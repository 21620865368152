import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

/* 
Component to accept feedback from the user 
Show the email field if no email is detected by a signed in user

params:
sendFeedback: (text) => 
email: (string)
*/
const ContactForm = ({ sendFeedback, emailAddress }) => {
	
	const titleDefault = "Contact Us"

	const [content, setContent] = React.useState("")
	const [email, setEmail] = React.useState(emailAddress)
	const [contentDisplay, setContentDisplay] = React.useState("visible")
	const [dialogueTitleText, setDialogueTitleText] = React.useState(titleDefault)
	const [open, setOpen] = React.useState(false)

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setTimeout(function() {
			setContent("")
			setDialogueTitleText(titleDefault)
			setEmail("")
			setContentDisplay("visible")
		}, 800)
	};

	const handleCloseAccept = () => {
		// If technical support is required, w
		setDialogueTitleText("Thank you! We'll get back to you as soon as we can.")
		setContentDisplay("hidden")

		// Take 3k chars for safety
		if (content.trim() !== "") sendFeedback(content.slice(0, 3000), email)

		//setOpen(false);
	};

	const handleFormChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setContent(event.target.value)
	}

	const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value)
	}

	const userField = <TextField
		autoFocus
		margin="dense"
		id="user-feedback"
		multiline
		rows={6}
		fullWidth
		//variant="standard"
		variant="outlined"
		onChange={handleFormChange}
		// sx={{ backgroundColor: "#2e2e2e", color: "#fff" }}
	/>

	const userFieldEmail = <TextField
		autoFocus
		margin="dense"
		id="user-email"
		label="Your Email"
		//type=""
		//fullWidth
		//variant="standard"
		variant="outlined"
		onChange={handleEmailChange}
		// sx={{ backgroundColor: "#2e2e2e", color: "#fff" }}
	/>

	return (
		<div>
			<Button onClick={handleClickOpen}>
				Send us a message
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth={"82%"} style={{"border": "none"}}>
				<DialogTitle>{dialogueTitleText}</DialogTitle>
				{/*<DialogTitle className="bg-blacklight text-white">{dialogueTitleText}</DialogTitle>*/}
				<DialogContent sx={{width: "56vw", visibility: contentDisplay}}>
				{/*<DialogContent sx={{width: "56vw", visibility: contentDisplay}} className="bg-blacklight text-white">*/}
					{/*<Typography sx={{mb: 2}}>
						Please send your feedback. We use your suggestions to improve the Article Workflows.
					</Typography>*/}
					{(emailAddress === undefined || emailAddress === "") ? userFieldEmail : ""}
					{userField}
				</DialogContent>
				{<DialogActions sx={{visibility: contentDisplay, marginRight: "1vw"}}>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleCloseAccept}>Submit</Button>
				</DialogActions>}
			</Dialog>
		</div>
	);
}

export default ContactForm;
