import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import Notify from "simple-notify";
import { firestore } from './../../firebaseService';
import "simple-notify/dist/simple-notify.min.css";
import HandleResetPassword from './handleResetPassword'
import HandleRecoverEmail from './handleRecoverEmail'
import HandleVerifyEmail from './handleVerifyEmail'

/*
Email action handler
*/
const AccountAuth = () => {

	const navigate = useNavigate()
	const auth = getAuth() // Docs => const auth = getAuth(app); const app = initializeApp(config);
	const [user, loading, error] = useAuthState(auth);
	const [searchParams] = useSearchParams();

	// Get the action to complete.
	const mode = searchParams.get('mode');
	// Get the one-time code from the query parameter.
	const actionCode = searchParams.get('oobCode');
	// (Optional) Get the continue URL from the query parameter if available.
	const continueUrl = searchParams.get('continueUrl');
	// (Optional) Get the language code if available.
	const lang = searchParams.get('lang') || 'en';

	const handler = () => {
		if (mode === 'resetPassword') {
			return <HandleResetPassword actionCode={actionCode} continueUrl={continueUrl} lang={lang} />
		}
		else if (mode === 'recoverEmail') {
			return <HandleRecoverEmail actionCode={actionCode} lang={lang} />
		}
		else if (mode === 'verifyEmail') {
			return <HandleVerifyEmail actionCode={actionCode} continueUrl={continueUrl} lang={lang} />
		}
		else {
			// Error: invalid mode
			
			let msg = `There was an error with your request, invalid mode${mode === null ? "." : ": " + mode }`
			const redirect = "You will be redirected in a few seconds."

			setTimeout(function(){
				navigate('/login')
			}, 6000)

			return <div className="p-5 text-center">{msg}<br></br><br></br>{redirect}</div>
		}
	}

	return (
		<div className="text-white">
		{handler()}
		</div>
	);
};

export default AccountAuth;
