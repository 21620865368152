import React from 'react';

const Failed = () => {
    return (
        <div>
            Failed
        </div>
    );
};

export default Failed;
