import React, { useEffect, useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Link, useSearchParams } from "react-router-dom";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.min.css";
import HashLoader from "react-spinners/HashLoader";
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { getAuth } from "firebase/auth";
import { firestore } from './../../firebaseService';
import { useAuthState } from "react-firebase-hooks/auth";
import LoginImage from '../../Components/LoginImage'
import PrivacyPolicy from '../../Components/PrivacyPolicy'
import TermsOfService from '../../Components/TermsOfService'

const SignUp = () => {
	
	const auth = getAuth()
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [searchParams] = useSearchParams();
	const [subscriptions, setSubscriptions] = useState([]);
	const [user] = useAuthState(auth);

	const [userInfo, setUserInfo] = useState({
		username: "",
		email: "",
		password: "",
	});

	const [error, setError] = useState({
		username: "",
		email: "",
		password: "",
	});

	const handlePaswordView = () => {
		setShowPassword(!showPassword);
	};

	const fetchSubscription = async () => {
		try {
			const snapshot = await firestore
			.collection("customers")
			.doc(user?.uid)
			.collection("subscriptions").get();
				const subscribeData = await Promise.all(
						snapshot.docs.map(async (doc) => {
								const subscribe = doc.data();
								subscribe.id = doc.id;
								return subscribe;
						})
				);
			 
				setSubscriptions(subscribeData);

		} catch (error) {
				console.error('Error fetching data:', error);
		}
	};

	const handleRegister = async () => {
		if (!userInfo.username) {
			setError({
				username: "Name is required.",
			});
		} else if (!userInfo.email) {
			setError({
				email: "Email is required.",
			});
		} else if (
			!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userInfo.email)
		) {
			setError({
				email: "Email address is invalid.",
			});
		} else if (!userInfo.password) {
			setError({
				password: "Password is required.",
			});
		} else if (userInfo.password.length < 8) {
			setError({
				password: "Password should 8+ characters.",
			});
		} else {
			setError({
				username: "",
				email: "",
				password: "",
			});
			try {
				
				setLoading(true);
				
				const values = {
					userName: userInfo.email,
					password: userInfo.password,
				};

				createUserWithEmailAndPassword(auth, userInfo.email, userInfo.password)
					.then(async (userCredential) => {
						
						// Signed in 
						console.log('userCredential', userCredential.user, userCredential);
						const user = userCredential.user;
						
						// Just checking in case user uses another provider linked to a pre-existing account
						if (!user.emailVerified) {
							console.log("Sending verification email")
							sendEmailVerification(user) // auth.currentUser
						}

						new Notify({
							status: "success",
							title: "Sign Up",
							text: "Successfully registered!",
							effect: "fade",
							speed: 500,
							customClass: null,
							customIcon: null,
							showIcon: false,
							showCloseButton: true,
							autoclose: true,
							autotimeout: 2000,
							gap: 20,
							distance: 20,
							type: 1,
							position: "right top",
						});

						// Handle if user was referred by affiliate
						try {
							if (window.tolt_referral) {
								
								window.tolt.signup(user.email) //userInfo.email)

								// Save the ID
								try {
									const writeResult = await firestore
										.collection("referrals")
										.doc(user.email)
										.set({ 
											toltReferral: window.tolt_referral
										});
								}
								catch (error) {
									console.log("Error recording referral", error)
								}
							}
						}
						catch (error) {
							console.log("Error with referral: ", user.email, window.tolt_referral, error)
						}

						setLoading(false);
						
						await fetchSubscription()

						setTimeout(function() {
							//if (subscriptions?.length > 1) {
							if (!user.emailVerified) {
								window.location.href = "/settings";
							} 
							else {
								window.location.href = "/dashboard";
							}
						}, 2400)
						
						// ...
					})
					.catch((error) => {
						const errorCode = error.code;
						const errorMessage = error.message;
						console.log('errorMessage==' + errorMessage);
						new Notify({
							status: "error",
							title: "Error",
							text: errorMessage,
							effect: "fade",
							speed: 500,
							customClass: null,
							customIcon: null,
							showIcon: false,
							showCloseButton: true,
							autoclose: true,
							autotimeout: 2000,
							gap: 20,
							distance: 20,
							type: 1,
							position: "right top",
						});
						setLoading(false);

					});


			} catch (error) {
				new Notify({
					status: "error",
					title: "Error",
					text: error.message,
					effect: "fade",
					speed: 500,
					customClass: null,
					customIcon: null,
					showIcon: false,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 2000,
					gap: 20,
					distance: 20,
					type: 1,
					position: "right top",
				});
				setLoading(false);
			}
		}
	};

	useEffect(() => {
		console.log('searchParams', searchParams.get('redirect'));

	}, [])

	return (
		<div className="grid grid-cols-12 bg-bluebutton h-full lg:h-screen">
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<LoginImage />
			</div>
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<div className="container mx-auto bg-white rounded px-10">
					<h3 className="text-3xl lg:text-4xl text-center">
						&nbsp;
					</h3>
					<h3 className="text-3xl lg:text-4xl text-center">
						&nbsp;
					</h3>
					<h3 className="text-3xl lg:text-4xl text-center">
						Sign-Up
					</h3>
					{/*<p className="text-xl py-3 text-gray100 text-center">
						Take out "Access to all features..."
					</p>*/}

					<p className="text-md py-2 text-gray100 mx-8 lg:mx-24 text-center">
						&nbsp;
					</p>
					{/*<div className="flex justify-between items-center py-3">
						<div
							className="w-1/3 lg:w-2/3 bg-gray100"
							style={{ height: "1px" }}
						></div>
						<p className="text-md py-2 text-gray100 w-1/3 text-center">
							Sign-Up
						</p>
						<div
							className="w-1/3 lg:w-2/3 bg-gray100"
							style={{ height: "1px" }}
						></div>
					</div>*/}
					<div className="flex flex-col gap-4">
						<div>
							<div className="mb-1 block">
								<Label htmlFor="name" value="Name" className="font-normal" />
							</div>
							<TextInput
								id="name"
								type="text"
								sizing="md"
								placeholder="Enter your name"
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										username: e.target.value,
									})
								}
							/>
							{error?.username ? (
								<span className="text-danger text-xs">{error.username}</span>
							) : null}
						</div>
						<div>
							<div className="mb-1 block">
								<Label htmlFor="email" value="E-mail" className="font-normal" />
							</div>
							<TextInput
								id="email"
								type="text"
								sizing="md"
								placeholder="name@example.com"
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										email: e.target.value,
									})
								}
							/>
							{error?.email ? (
								<span className="text-danger text-xs">{error.email}</span>
							) : null}
						</div>
						<div className="relative">
							<div className="mb-1 block">
								<Label
									htmlFor="password"
									value="Password"
									className="font-normal"
								/>
							</div>
							<TextInput
								id="password"
								type={`${showPassword ? "text" : "password"}`}
								sizing="md"
								placeholder="8+ characters required "
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										password: e.target.value,
									})
								}
							/>
							<FontAwesomeIcon
								icon={showPassword ? faEyeSlash : faEye}
								className="absolute top-10 right-0"
								onClick={handlePaswordView}
							/>
							{error?.password ? (
								<span className="text-danger text-xs">{error.password}</span>
							) : null}
						</div>
						<Button
							className="capitalize text-2xl mx-auto my-2 w-full bg-lightBluebtn py-2 mt-5"
							onClick={handleRegister}
							disabled={loading}
						>
							{loading ? (
								<HashLoader
									color={"#fff"}
									loading={loading}
									size={20}
									aria-label="Loading Spinner"
									data-testid="loader"
								/>
							) : (
								"Sign Up"
							)}
						</Button>
					</div>
					<p className="text-md py-2 text-gray100 lg:mx-24 text-center">
						Already have an account?
						<a href="/login" className="color-link-dark px-1">
							Login
						</a>
					</p>
					<p className="text-sm py-2 text-gray100 lg:mx-24 pb-10 text-center">
						By creating an Account, you agree to our
						<PrivacyPolicy />
						and
						<TermsOfService />
					</p>
				</div>
			</div>
		</div>
	);
};

export default SignUp;
