import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DashboardNav } from "../../Components";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import SubscriptionForm from './../Products/SubscriptionForm';
import Notify from "simple-notify";
import { firestore } from './../../firebaseService';
import "simple-notify/dist/simple-notify.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import VerifyEmailModal from '../../Components/VerifyEmail'
import DashboardFooter from '../../Components/DashboardFooter'
import { collection, doc, getDoc, getDocs, query, orderBy, startAt, limit, where } from "firebase/firestore";
// import { useStripe } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
// import {STRIPE_PUBLIC_KEY} from './../../config/env';
// const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Dashboard = ({ user, loading, auth }) => {

	const [subscriptions, setSubscriptions] = useState([]);
	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate()
	// const auth = getAuth()
	// const [user, loading, error] = useAuthState(auth);
	const displayName = user && user.displayName ? user.displayName : ""
	const welcomeMessage = displayName === "" ? "" : `Welcome ${displayName}.`
	const [headerKey, setHeaderKey] = useState(0);
 
	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
		fetchSubscription();
	}, [user, loading])

	useEffect(() => {
		if (searchParams.get('success')) {	
			new Notify({
				status: "success",
				title: "Congratulations",
				text: `${searchParams.get('name')} successfully subscribed!`,
				effect: "fade",
				speed: 300,
				showIcon: false,
				showCloseButton: true,
				autoclose: true,
				autotimeout: 10000,
				gap: 40,
				distance: 40,
				type: 1,
				position: "right top",
			});
		}
		if (searchParams.get('fail')) {
			new Notify({
			  status: "error",
			  title: "Error",
			  text: 'Payment has failed.',
			  effect: "fade",
			  speed: 300,
			  customClass: null,
			  customIcon: null,
			  showIcon: false,
			  showCloseButton: true,
			  autoclose: true,
			  autotimeout: 2000,
			  gap: 20,
			  distance: 20,
			  type: 1,
			  position: "right top",
			});
			}
	}, [])

	const fetchSubscription = async () => {
		
		try {
			const snapshot = await firestore
			.collection("customers")
			.doc(user?.uid)
			.collection("subscriptions").get();
				const subscribeData = await Promise.all(
						snapshot.docs.map(async (doc) => {
								const subscribe = doc.data();
								subscribe.id = doc.id;
								return subscribe;
						})
				);
				setSubscriptions(subscribeData);
				setHeaderKey((headerKey) => headerKey + 1)

				console.log({ subscribeData });

		} catch (error) {
				console.error('Error fetching data:', error);
		}
	};

	console.log("User: ", user)

	const showModal = !loading && user && user.email && !user.emailVerified

	return (
		<div>
			<DashboardNav subscriptions={subscriptions} key={headerKey} user={user} loading={loading} auth={auth}/>

			{!showModal ? "" : 
				<div className="flex justify-center items-center">
					<VerifyEmailModal emailAddress={user.email} verified={user.emailVerified} />
				</div>
			}

			{loading ? "" : <div className="bg-blackdark text-white py-10">
				{/*<div className="container mx-auto px-3 md:px-5 bg-blackmedium">*/}
				<div className="container mx-auto px-3 md:px-5">
					<div className="bg-blacklight">
						<h1 className="text-4xl font-medium p-5 bg-blacktitle rounded">Dashboard</h1>
						{/*<div className="bg-[#7c7cff] rounded py-5 px-3 md:px-8">
						<div className="grid grid-cols-12">
							<div className="col-span-12 md:col-span-4">
								<h2 className="text-white text-2xl font-bold">{welcomeMessage}</h2>
								<p className="text-white text-md py-2">
									Use this guide to learn how to write your first article using
									out AI writing tool.
								</p>
								<div className="flex items-center pt-4">
									<Button className="capitalize text-2xl bg-[#c96dff] text-white rounded-none">
										learn now
									</Button>
									<Button className="capitalize mx-2 text-2xl bg-transparent">
										New Article
									</Button>
								</div>
							</div>
							<div className="col-span-12 md:col-span-8"></div>
						</div>
					</div>*/}
					<div className="grid grid-cols-12 gap-5 md:gap-10 py-5 px-4">
						<div className="col-span-18 md:col-span-8 lg:col-span-10 bg-blacklight p-4">
							<p>Welcome to Word Galaxy, your SEO-Optimized Article Generator.
							</p>
							<br></br>
							<p>
								Use the menu above to: 
							</p>
							<ul className="mt-2">
								<li>
									<FontAwesomeIcon icon={faClipboardList} color="rgb(15, 126, 248)" className="pr-4 mt-2" size="lg"/>
									Create articles
								</li>
								<li>
									<FontAwesomeIcon icon={faClipboardList} color="rgb(15, 126, 248)" className="pr-4 mt-2" size="lg"/>
									Review your documents
								</li>
								<li>
									<FontAwesomeIcon icon={faClipboardList} color="rgb(15, 126, 248)" className="pr-4 mt-2" size="lg"/>
									Manage your settings and subscription
								</li>
							</ul>
						</div>
					</div>
					</div>
				
					<div className="dash_subscription_sec">
					</div> 
					{/*<h2 className="text-xl font-medium py-4 mt-8 px-4">Your Plan</h2>
					<div className="flex justify-between items-center flex-wrap lg:flex-nowrap p-3 bg-blacklight rounded px-4">
						<p className="capitalize">
							<span className="font-medium">Free: </span> 1,322 / 2, 500 words
							used
						</p>
						<Button className="capitalize bg-lightBluebtn text-white my-3 lg:my-0 px-2 py-1 rounded">
							Settings
						</Button>
					</div>*/}
				</div>
			</div>}
			<DashboardFooter />
		</div>
	);
};

export default Dashboard;
