import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "flowbite-react";
import { getAuth, applyActionCode } from "firebase/auth";

const HandleVerifyEmail = ({ actionCode, continueUrl, lang }) => {

	// Localize the UI to the selected language as determined by the lang parameter.

	const auth = getAuth()
	const navigate = useNavigate()
	const [loading, setLoading] = React.useState(true)
	const [codeError, setCodeError] = React.useState("")
	
	const applyCode = async () => {
		
		try {
			// Verify the password reset code is valid.
		 	let resp = await applyActionCode(auth, actionCode)
		 	console.log("Verify resp: ", resp)
		 	setLoading(false)
		 	// Email address has been verified
		}
		catch (error) {
			// Code is invalid or expired. Ask the user to verify their email address again.
			//console.log("Error applying code", error)
			setCodeError("Please verify your email again. The link is invalid or expired.")
		}

		setLoading(false)
	}

	useEffect(() => {
		applyCode()
	}, [])

	// On success
	const confirmationMessage = 
		<div className="flex-col" style={{alignItems: "center"}}>
			<div className="m-4 text-center">Your email address has been verified.</div>
			<Link to="/settings">
				<Button className="capitalize text-2xl lg:mx-auto bg-lightBluebtn text-white my-3 md:my-0">
					Return to Dashboard
					{/*Welcome to WordGalaxy!*/}
				</Button>
			</Link>
		</div>

	const errorAndResetButton = (message) => {
		return (
			<div className="flex-col" style={{alignItems: "center"}}>
				<div className="m-4 text-center">{message}</div>
				<Link to="/dashboard">
					<Button className="capitalize text-2xl lg:mx-auto bg-lightBluebtn text-white my-3 md:my-0">
						Return to Dashboard
					</Button>
				</Link>
			</div>
		);
	}

	const content = () => {
		if (loading) {
			return "Loading"
		}
		else if (codeError) {
			return errorAndResetButton(codeError)
		}
		else {
			// If loading is finished with no error, verification was successful
			
			// TODO: If a continue URL is available, display a button which on
		    // click redirects the user back to the app via continueUrl with
		    // additional state determined from that URL's parameters

			return confirmationMessage
		}
	}

	return (
		<div>
			{content()}
		</div>
	);
};

export default HandleVerifyEmail;
