import * as docx from "docx";
//import { Document, Packer, Paragraph, TextRun, HeadingLevel } from "docx";
import { saveAs } from 'file-saver';

/* Map HTML Header tags to a default font size for docx */
const htmlHeaderToWordFontSizeMapping = {
	H1: 44,
	H2: 32,
	H3: 24,
	H4: 18,
	H5: 14,
};

const htmlHeaderToWordSpacingAfter = {
	H1: 250,
	H2: 150,
	H3: 150,
	H4: 150,
	H5: 150,
};

// Title, size 56, after: 250

// To understand formatting see:
// https://github.com/dolanmiu/docx/blob/master/demo/11-declaritive-styles-2.ts

const paragraphStyles = [
	{
			id: "Heading1",
			name: "Heading 1",
			basedOn: "Normal",
			next: "Normal",
			quickFormat: true,
			run: {
					size: htmlHeaderToWordFontSizeMapping.H1,
					bold: true,
			},
			paragraph: {
					spacing: {
							// before: 240,
							after: htmlHeaderToWordSpacingAfter.H1
					},
			},
	},
	{
			id: "Heading2",
			name: "Heading 2",
			basedOn: "Normal",
			next: "Normal",
			quickFormat: true,
			run: {
					size: htmlHeaderToWordFontSizeMapping.H2,
					bold: true
			},
			paragraph: {
					spacing: {
							after: htmlHeaderToWordSpacingAfter.H2 
					},
			},
	},
	{
			id: "Heading3",
			name: "Heading 3",
			basedOn: "Normal",
			next: "Normal",
			quickFormat: true,
			run: {
					size: htmlHeaderToWordFontSizeMapping.H3,
					bold: true,
			},
			paragraph: {
					spacing: {
							after: htmlHeaderToWordSpacingAfter.H3 
					},
			},
	},
	{
			id: "Heading4",
			name: "Heading 4",
			basedOn: "Normal",
			next: "Normal",
			quickFormat: true,
			run: {
					size: htmlHeaderToWordFontSizeMapping.H4,
					bold: true,
			},
			paragraph: {
					spacing: {
							after: htmlHeaderToWordSpacingAfter.H4
					},
			},
	},
	{
			id: "Heading5",
			name: "Heading 5",
			basedOn: "Normal",
			next: "Normal",
			quickFormat: true,
			run: {
					size: htmlHeaderToWordFontSizeMapping.H5,
					bold: true,
			},
			paragraph: {
					spacing: {
							after: htmlHeaderToWordSpacingAfter.H5
					},
			},
	},
	{
			id: "Heading6", // Just a fake heading element to bold text
			name: "Heading 6",
			basedOn: "Normal",
			next: "Normal",
			quickFormat: true,
			run: {
					size: 12,
					bold: true,
			},
			// paragraph: {
			// 		spacing: {
			// 				after: htmlHeaderToWordSpacingAfter.H5
			// 		},
			// },
	},
	{
		id: "normalPara",
		name: "Normal Para",
		basedOn: "Normal",
		next: "Normal",
		quickFormat: true,
		run: {
				font: "Calibri",
				size: 26,
				bold: true,
		},
		paragraph: {
				spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
				rightTabStop: docx.TabStopPosition.MAX,
				leftTabStop: 453.543307087,
		},
	},
	{
		id: "wellSpaced",
		name: "Well Spaced",
		basedOn: "Normal",
		paragraph: {
				spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
		},
	},
	{
		id: "fakeBold",
		name: "Fake Bold",
		basedOn: "Normal",
		next: "Normal",
		quickFormat: true,
		run: {
				size: 28,
				bold: true,
		},
	}
	// {
	//     id: "wellSpaced",
	//     name: "Well Spaced",
	//     basedOn: "Normal",
	//     quickFormat: true,
	//     paragraph: {
	//         spacing: { line: 276, before: 20 * 72 * 0.1, after: 20 * 72 * 0.05 },
	//     },
	// },
	// {
	//     id: "ListParagraph",
	//     name: "List Paragraph",
	//     basedOn: "Normal",
	//     quickFormat: true,
	// },
]

/*
Another example of style settings
https://dev.to/iainfreestone/how-to-create-a-word-document-with-javascript-24oi
*/

// Not for generic use
// function extractLeadingBoldSections(textWithHtml) {

// 	//let x = "<strong>This element is bold.</strong>"
// 	//let x = "<strong>This element is bold.</strong>And possibly<strong> another spot</strong>. The rest of the article"

// 	let text = textWithHtml;
// 	let elems = []
// 	// Separate article to not be effected
// 	let article = ""

// 	// Sanity check to not cannibalize the whole text in case strong tags found later
// 	// First h1 is article title
// 	let firstTitleIdx = textWithHtml.indexOf("<h1>")

// 	if (firstTitleIdx !== -1) {
// 		text = textWithHtml.substring(0, firstTitleIdx)
// 		article = textWithHtml.substring(firstTitleIdx)
// 	}

// 	while (text.includes("<strong>") && text.includes("</strong>")) {

// 	  let i = text.indexOf("<strong>")
// 	  let e = text.indexOf("</strong>")
// 	  let before = text.substring(0, i)
// 	  let bold = text.substring(i + "<strong>".length, e)
	  
// 	  //elems.push(before)
// 	  //elems.push(bold)

// 	  elems.push(
// 	  	new docx.Paragraph({
// 				children: [
// 					new docx.TextRun({ text: before }),
// 					new docx.TextRun({ text: bold, bold: true })
// 				]
// 		}))

// 	  let remaining = text.substring(e + "</strong>".length)
// 	  text = remaining
// 	}

// 	//if (text !== "") elems.push(text)
// 	//console.log(elems)
// 	//console.log("Res: ", elems.join(""))

// 	return {
// 		textRuns: elems,
// 		remainingText: text + article
// 	}
// }


/*
Create a docx given text containing HTML
Does not handle nested elements, 
which is fine since result doesn't contain nested
Would need to do in-order traversal
*/
function textStringToDocX(textWithHtmlInitial, debug) {

	// Handle the initial <strong> tags separately because they're not parsed, not as strong or <b>
	//let textNoStrong = extractLeadingBoldSections(textWithHtmlInitial)
	//let textWithHtml = textNoStrong.remainingText
	let textWithHtml = textWithHtmlInitial.replace(/<strong>/g, '').replace(/<\/strong>/g, "").replace("<h1>", "\n\n<h1>")
	//console.log(textWithHtmlInitial)

	// https://developer.mozilla.org/en-US/docs/Web/API/DOMParser
	const parser = new DOMParser();
	const docHtml = parser.parseFromString(textWithHtml, "text/html");
	//doc.documentElement.textContent

	const errorNode = docHtml.querySelector("parsererror");
	
	if (errorNode) {
		// parsing failed
		console.error("Error converting to docx: ", errorNode)
		throw new Error(errorNode)
	} 
	
	const elements = docHtml.body.childNodes;
	//const resultElems = [...textNoStrong.textRuns];
	const resultElems = []

	// Can modify html doc
	//let x = docHtml.getElementById("title")
	//x.textContent = "New title"

	function addHeader(e, level) {
		resultElems.push(new docx.Paragraph({
			text: e.innerHTML,
			heading: level
		}));
	}

	// Handle any preceding strong sections

	// Does not handle nested, would need to do in-order traversal
	for (let i = 0; i < elements.length; i++) {
		
		const e = elements[i];

		if (e.tagName === 'P') {
			resultElems.push(new docx.Paragraph({
				children: [
					new docx.TextRun(e.innerHTML)// + "\n")
					// new TextRun({ text: e.innerHTML, bold: true })
				],
				// style: "normalPara"
				// spacing: { 
				// 	after: 120,
				// }
			}));
			// Better than using spacing above
			resultElems.push(new docx.Paragraph({}))
		} 
		else if (e.tagName === 'H1') addHeader(e, docx.HeadingLevel.HEADING_1)
		else if (e.tagName === 'H2') addHeader(e, docx.HeadingLevel.HEADING_2)
		else if (e.tagName === 'H3') addHeader(e, docx.HeadingLevel.HEADING_3)
		else if (e.tagName === 'H4') addHeader(e, docx.HeadingLevel.HEADING_4)
		else if (e.tagName === 'H5') addHeader(e, docx.HeadingLevel.HEADING_5)
		else if (e.tagName === 'H6') addHeader(e, docx.HeadingLevel.HEADING_6)
		// else if (e.tagName === 'DIV') {
		// 	resultElems.push(new docx.Paragraph({ 
		// 		children: [new docx.TextRun({ text: e.innerHTML })],
		// 		style: "fakeBold"
		// 	}));
		// }
		else {
			console.warn("Unrecognized elem: ", e.tagName, e)
			resultElems.push(new docx.Paragraph({ text: e.innerHTML }));
		}
	}

	const sections = [{
		properties: {},
		children: resultElems
	}]

	const doc = new docx.Document({ sections: sections,
		styles: {
			paragraphStyles: paragraphStyles
		},
	 })

	if (debug) console.log("docX: ", resultElems)

	return doc
}

async function exportTextWithHtmlAsDocX(textWithHtml, fileName) {

	const doc = textStringToDocX(textWithHtml, false)
	const blob = await docx.Packer.toBlob(doc)
	saveAs(blob, fileName + ".docx");

}

export { exportTextWithHtmlAsDocX }

// const doc = new docx.Document({
 //    sections: [
 //      {
 //        properties: {},
 //        children: [
 //          new docx.Paragraph({
 //            children: [
 //              new docx.TextRun("Hello World"),
 //              new docx.TextRun({
 //                text: "Foo Bar",
 //                bold: true
 //              }),
 //              new docx.TextRun({
 //                text: "\tGithub is the best",
 //                bold: true
 //              })
 //            ]
 //          })
 //        ]
 //      }
 //    ]
 //  });

// const sampleArticle = "<p><strong>Meta Title</strong></p><p>Keto Trail Mix: The Ultimate Guide to A Healthy Snack</p><p>" +
// "<strong>Meta Description</strong></p><p>Discover the benefits of keto trail mix as a healthy snack option. " + 
// "Learn how to make your own and creative ways to enjoy it. Perfect for low-carb diets!</p><h1>Power Up with Keto Trail Mix: " +
// "The Ultimate Guide to A Healthy and Satisfying Snack</h1><h2>The Power of Keto Trail Mix: A Healthy Snack for the Keto Diet" + 
// "</h2><p>The ketogenic diet, popularly known as keto, has gained a lot of attention in recent years. The diet entails " +
// "consuming high amounts of healthy fats, moderate amounts of protein, and low carbohydrates to promote weight loss while " +
// "maintaining good health. People on the keto diet avoid most sugary foods and grains but still need to fuel up with snacks " + 
// "that are compatible with their dietary plan. </p><h3>Why You Need Trail Mix In Your Life</h3><p>Trail mix is a convenient " + 
// "and nutritious snack option that works well for those on the keto diet. It's made up of a combination of nuts, seeds, and " + 
// "dried fruits which provide healthy fats, protein and fiber that help keep you full for long periods. This mixture " + 
// "also provides an excellent source of energy as it contains slow-digesting carbohydrates that release energy gradually. " + 
// "</p><h3>A Brief Introduction to Keto</h3><p>The keto diet is based on the premise that by eating fat-rich foods instead " +
// "</p>"
// textStringToDocX(sampleArticle)




