import React from "react";
import { Card } from "flowbite-react";

const SEOCard = ({ cardTitle, cardText }) => {
  return (
    <Card href="#" className="rounded-md px-3 seo-card">
      <h5 className="text-2xl font-bold text-black">
        {cardTitle}
      </h5>
      <p className="font-normal text-black">{cardText}</p>
    </Card>
  );
};

export default SEOCard;
