import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material' 
import { Link } from '@mui/material';

/* */
export default function FaqModal(params) {
	
	return (
		<div style={{textAlign: "right"}}>
			<Dialog open={params.open} onClose={params.handleClose} maxWidth={"82%"}>
				{/*<DialogTitle>FAQ</DialogTitle>*/}
				<DialogContent sx={{width: "70vw"}}>
					<DialogContentText sx={{p: 4}}>
					<Typography id="modal-modal-title" variant="h4" component="h4" fontWeight="bold" mb={5} textAlign="center">FAQ
					</Typography>

					<Typography variant="h5" fontWeight="bold" mb={1}>What is Quick Article Workflow?</Typography>
					<Typography mb={4}>A simple and intuitive interface with customizable settings that allows you to generate articles.</Typography>
					
					<Typography variant="h5" fontWeight="bold" mb={1}>How to get started?</Typography>
					<Typography mb={4}>First, you need an API key, so sign up for the <Link className="link-blue" href="https://platform.openai.com/account/api-keys" target="_blank" rel="noopener noreferrer">OpenAI API platform</Link>. Once you get your API key, you can copy and paste it into the Quick Article Workflow tool to start generating content with ChatGPT.</Typography>
					
					<Typography variant="h5" fontWeight="bold" mb={1}>What is the “API key?”</Typography>
					<Typography mb={4}>The API key is the token that allows users to access the OpenAI API and use the ChatGPT service.
					To obtain an API key for ChatGPT, you need to sign up for the OpenAI API <Link className="link-blue" href="https://platform.openai.com/account/api-keys" target="_blank" rel="noopener noreferrer">(click here)</Link> platform and subscribe to the plan that suits your needs. Once you've subscribed, you'll receive an API key that you can copy and paste into the Quick Article Workflow.</Typography>
					
					<Typography variant="h5" fontWeight="bold" mb={1}>How Do I “Fund” an OpenAI Key so I can begin using it?</Typography>
					<Typography mb={4}>You can fund you OpenAI account <Link className="link-blue" href="https://platform.openai.com/account/billing/overview" target="_blank" rel="noopener noreferrer">here</Link>.</Typography>

					<Typography variant="h5" fontWeight="bold" mb={1}>Where do I find my Secret API Key?</Typography>
					<Typography mb={4}>Follow this OpenAI article <Link className="link-blue" href="https://help.openai.com/en/articles/4936850-where-do-i-find-my-secret-api-key" target="_blank" rel="noopener noreferrer">here</Link> for a guide on locating/creating a secret API key.</Typography>

					<Typography variant="h5" fontWeight="bold" mb={1}>What does “article length” mean?</Typography>
					<Typography mb={4}>Because Quick Article Workflow is built on top of ChatGPT, the output and length of each article can vary greatly. The article length toggle bar is to help users have some control of the length of the output. We recommend testing to see what length setting is best for you.</Typography>

					{/*<Typography variant="h5" fontWeight="bold" mb={1}>Does this tool cost money to use?</Typography>*/}
					{/*<Typography mb={4}>Yes, to use the ChatGPT language model through the Quick Article Workflow tool, users need to subscribe to the <Link className="link-blue" href="https://platform.openai.com/account/api-keys" target="_blank" rel="noopener noreferrer">OpenAI API platform</Link> and choose a plan that suits their needs. From our experience, the cost is less than 5 cents per article. */}

					<Typography variant="h5" fontWeight="bold" mb={1}>How can I provide feedback on the quality of the output generated?</Typography>
					{/*If you have feedback on the quality of the output generated by Quick Article Workflow, you can */}
					<Typography mb={4}>Share feedback with our team by going to the Dashboard then navigating to the "Support" page from the top menu bar. Type your feedback and click submit! We'll be happy to hear from you!</Typography>
					
					<Typography variant="h5" fontWeight="bold" mb={1}>How Do I Monitor my OpenAI token usage?</Typography>
					<Typography mb={4}>Monitor your usage on OpenAI's account page <Link className="link-blue" href="https://platform.openai.com/account/usage" target="_blank" rel="noopener noreferrer">here</Link>.</Typography>

					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}