import React from 'react';
import SubscriptionForm from './SubscriptionForm';

const Products = () => {
  return (    
      <SubscriptionForm />
  );
};

export default Products;
