import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Label, TextInput } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { HashLoader } from "react-spinners";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.min.css";
//import { auth } from './../../firebaseService';
import { getAuth, confirmPasswordReset } from "firebase/auth";
import LoginImage from '../../Components/LoginImage'

const ResetPassword = ({ actionCode, accountEmail }) => {
	
	const auth = getAuth()
	const navigate = useNavigate()
	const [showNewPassword, setShowNewPassowrd] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [loading, setLoading] = useState(false);

	const [userInfo, setUserInfo] = useState({
		email: accountEmail,
		newPassword: "",
		confirmPassword: ""
	});

	const [error, setError] = useState({
		email: "",
		newPassword: "",
		confirmPassword: "",
	});

	const handleReset = async () => {

		if (!userInfo.email) {
			setError({
				email: "Email is required.",
			});
		} 
		// else if (
		// 	!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userInfo.email)
		// ) {
		// 	setError({
		// 		email: "Email address is invalid.",
		// 	});
		// } 
		else if (!userInfo.confirmPassword) {
			setError({
				confirmPassword: "Confirmation is required.",
			});
		} 
		else if (!userInfo.newPassword) {
			setError({
				newPassword: "New password is required.",
			});
		} 
		else if (userInfo.newPassword.length < 8) {
			setError({
				newPassword: "Password should have 8 or more characters.",
			});
		} 
		else if (userInfo.confirmPassword !== userInfo.newPassword) {
			setError({
				confirmPassword: "Password and confirmation do not match.",
			});
		} 
		else {
			
			setError({
				email: "",
				newPassword: "",
				confirmPassword: ""
			});

			setLoading(true);

			try {
				await confirmPasswordReset(auth, actionCode, userInfo.newPassword)
				//await auth.confirmPasswordReset(actionCode, userInfo.newPassword);

				// Password reset successful, redirect or display success message

				// TODO: Display a link back to the app, or sign-in the user directly
				// if the page belongs to the same domain as the app:
				// auth.signInWithEmailAndPassword(accountEmail, newPassword);

				// TODO: If a continue URL is available, display a button which on
				// click redirects the user back to the app via continueUrl with
				// additional state determined from that URL's parameters.

				new Notify({
					status: "success",
					title: "Success",
					text: "Your password has been successfully reset. You will be redirected in a few seconds.",
					effect: "fade",
					speed: 300,
					customClass: null,
					customIcon: null,
					showIcon: false,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 4000,
					gap: 20,
					distance: 20,
					type: 1,
					position: "right top",
				});

				//window.location.pathname = "/login";

				setTimeout(function(){
					navigate('/dashboard')
				}, 6600)

			} 
			catch (error) {
				
				console.log(error);
				
				// Handle error and display an appropriate message to the user
				let msg = error.message ? "Error: " + error.message : "Something went wrong, please try again."

				new Notify({
					status: "error",
					title: "Error",
					text: msg,
					effect: "fade",
					speed: 300,
					customClass: null,
					customIcon: null,
					showIcon: false,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 4000,
					gap: 20,
					distance: 20,
					type: 1,
					position: "right top",
				});
			}

			setLoading(false);
		}
	};

	const handleNewPaswordView = () => {
		setShowNewPassowrd(!showNewPassword);
	};

	const handleConfirmPaswordView = () => {
		setShowConfirmPassword(!showConfirmPassword);
	};

	return (
		<div className="grid grid-cols-12 h-full lg:h-screen bg-white">
			<div className="col-span-12 lg:col-span-6 bg-bluebutton py-10 flex justify-center items-center">
				<LoginImage />
			</div>
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<div className="container mx-auto">
					<h3 className="text-4xl text-center py-2">Password Reset</h3>
					<p className="text-gray100 text-lg py-3 text-center">
						Choose a new password.
					</p>
					<div className="flex flex-col gap-4">
						<div>
							<div className="mb-1 block">
								<Label htmlFor="email" value="E-mail" className="font-normal" />
							</div>
							<div>{accountEmail}</div>
							{/*<TextInput
								id="email"
								type="text"
								sizing="md"
								//placeholder="name@example.com"
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								value={accountEmail}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										email: e.target.value,
									})
								}
							/>*/}
							{error.email ? (
								<span className="text-danger text-xs">{error.email}</span>
							) : null}
						</div>
						<div className="relative">
							<div className="mb-1 block">
								<Label
									htmlFor="newPassword"
									value="New Password"
									className="font-normal"
								/>
							</div>
							<TextInput
								id="newPassword"
								type={`${showNewPassword ? "text" : "password"}`}
								sizing="md"
								placeholder="(at least 8 characters)"
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										newPassword: e.target.value,
									})
								}
							/>
							<FontAwesomeIcon
								icon={showNewPassword ? faEyeSlash : faEye}
								className="absolute top-10 right-0 hover:cursor-pointer"
								onClick={handleNewPaswordView}
							/>
							{error.newPassword ? (
								<span className="text-danger text-xs">{error.newPassword}</span>
							) : null}
						</div>
						<div className="relative">
							<div className="mb-1 block">
								<Label
									htmlFor="confirmPassword"
									value="Confirm Password"
									className="font-normal"
								/>
							</div>
							<TextInput
								id="confirmPassword"
								type={`${showConfirmPassword ? "text" : "password"}`}
								sizing="md"
								placeholder=""
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										confirmPassword: e.target.value,
									})
								}
							/>
							<FontAwesomeIcon
								icon={showConfirmPassword ? faEyeSlash : faEye}
								className="absolute top-10 right-0 hover:cursor-pointer"
								onClick={handleConfirmPaswordView}
							/>
							{error.confirmPassword ? (
								<span className="text-danger text-xs">{error.confirmPassword}</span>
							) : null}
						</div>
						<Button
							className="capitalize text-2xl mx-auto my-2 px-24 bg-lightBluebtn py-2 mt-5"
							onClick={handleReset}
							disabled={loading}
						>
							{loading ? (
								<HashLoader
									color={"#fff"}
									loading={loading}
									size={20}
									aria-label="Loading Spinner"
									data-testid="loader"
								/>
							) : (
								"reset"
							)}
						</Button>
						<p className="text-md py-2 text-gray100 mx-10 lg:mx-24 text-center ">
							<a href="/login" className="color-link-dark px-1 mx-1 underline">
								Return to Login
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
