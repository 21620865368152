import React, { useState, useEffect, useRef } from "react";
import { Button } from "flowbite-react";
import { FAQs, Header, PricingCard, SEOCard } from "../../Components";
import { Screenshot } from "../../assets";
import { Link } from "react-router-dom";
import { Footer } from "../../Components";
import { WordgalaxyWhite2 } from "../../assets";
import BarLoader from "react-spinners/BarLoader";
import RingLoader from "react-spinners/RingLoader";
// import PricingPagehtml from "../../Components/PricingPagehtml"; 
import { WordgalaxyBlack, WordgalaxyWhite } from "../../assets";
import SubscriptionForm from './../Products/SubscriptionForm';
import { Languages } from '../../languages.js'
import '../../css/common.css'
import { Fade } from '@mui/material';
import ReactPlayer from 'react-player/vimeo'

const cardsData = [
  {
    id: 1,
    title: "Generate original, high-quality long-form content",
    text: "Just enter a keyword and let the AI do the magic!",
  },
  {
    id: 2,
    title: "Optimize content for SEO",
    text: "Use Word Galaxy to inject secondary keywords and LSI into your articles.",
  },
  {
    id: 3,
    title: "Build content at lightning speed with the help of AI",
    text: "GPT-3.5+ and our other machine learning technologies let us give you some amazing solutions that you won't find anywhere else.",
  },
];

const openAIKeyLink = <span>You can create an OpenAI key <a href="https://platform.openai.com/account/api-keys" style={{color: "#2f56ff"}} target="_blank"> here</a></span>
const fundingLink = <span>You can fund your OpenAI account <a href="https://platform.openai.com/account/billing/overview" style={{color: "#2f56ff"}} target="_blank"> here</a></span>
const platformLink = <span>You can monitor your OpenAI token usage <a href="https://platform.openai.com/account/usage" style={{color: "#2f56ff"}} target="_blank"> here</a></span>

const FAQsData = [
  {
    id: 1,
    question: "What is Word Galaxy?",
    answer:
      "Word Galaxy is an innovative content creation tool designed to help you create high-quality, optimized content that ranks higher in search engines. It provides various features and functionalities to enhance your content creation process, all with the goal of an incredibly SEO-optimized output.",
  },
  {
    id: 2,
    question: "How does Word Galaxy work?",
    answer:
      "Word Galaxy utilizes advanced algorithms and language models (ChatGPT) to generate engaging and SEO-friendly content. Simply input your topic or keywords, and the tool will provide you with relevant suggestions, outlines, and even complete sentences to jumpstart your writing process.",
  },
  {
    id: 3,
    question: "Is Word Galaxy suitable for both beginners and experienced writers?",
    answer:
      "Yes, Word Galaxy caters to users of all skill levels. Whether you're a seasoned writer or just starting, the tool provides valuable assistance and inspiration throughout the content creation process. It offers customizable options to adapt to your writing style and preferences.",
  },
  {
    id: 4,
    question: "Is my content secure and private when using Word Galaxy?",
    answer:
      "Word Galaxy takes privacy and security seriously. Your content is treated as confidential and is never shared with third parties. You can use the tool with confidence, knowing that your work remains secure and private.",
  },
  {
    id: 5,
    question: "What payment options are available for Word Galaxy?",
    answer:
      "Word Galaxy accepts major credit cards (Visa, Mastercard, American Express) as well as payment through popular platforms like PayPal. You can choose the payment method that is most convenient for you.",
  },
  {
    id: 6,
    question: "Is there a free trial available for Word Galaxy?",
    answer:
      "While Word Galaxy does not offer a free trial at the moment, we provide a comprehensive overview of its features and benefits on our website.",
  },
  {
    id: 7,
    question: "What kind of support is available for Word Galaxy users?",
    answer:
      "Word Galaxy offers customer support to assist users with any questions, concerns, or technical issues. You can reach out to our support team through email or the contact form on our website.",
  },
  {
    id: 8,
    question: "How Do I Create an OpenAI Key?",
    answer: openAIKeyLink
  },
  {
    id: 9,
    question: "How Do I “Fund” an OpenAI Key so I can begin using it?",
    answer: fundingLink
  },
  {
    id: 10,
    question: "How Do I Monitor my OpenAI token usage?",
    answer: platformLink
  }
];

// Re-order the languages to appear alphabetical at least on large display
const langs = Languages.filter((lang) => lang.name !== "Hebrew")
let languagesWithOrder = [];

try {
  const nLanguageCols = 6
  const nPerCol = Math.ceil(langs.length / nLanguageCols);
  const idxToLangColumn = Array.from({ length: nLanguageCols }, () => []);
  langs.forEach((lang, idx) => {
    const colIdx = Math.floor(idx / nPerCol);
    idxToLangColumn[colIdx].push(lang)
  })
  let i = 0;
  while (i < langs.length) {
    const idx = i % nLanguageCols
    const l = idxToLangColumn[idx].shift()
    languagesWithOrder.push(l)
    i += 1
  }

  // console.log(languagesWithOrder)
}
catch (error) {
  console.error("Error ordering languages: ", error)
  languagesWithOrder = langs
}


const Home = ({ navPath }) => {

  const texts = [
    "AUTOMATIC SEO OPTIMIZED CONTENT",
    "EASY TO OPERATE INTERFACE",
    "LONG-FORM BLOGS AND ARTICLES",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const resultRef = useRef(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % texts.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, texts.length]);

  const [loadScreenVisible, setLoadScreenVisible] = useState(true)

  // setTimeout(function() {
  //   setLoadScreenVisible(false)
  // }, 2500)

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadScreenVisible(false);
    }, 1710);

    return () => clearTimeout(timer);
  }, []);


  return (
    <>
      <div>
      {(
        <Fade in={loadScreenVisible} appear={false} timeout={1340} easing={"ease-out"}>
          <div className="flex-col justify-center items-center" style={{width: "100vw", height: "100vh", backgroundColor: "black", zIndex: 100, position: "fixed"}}>
            <div style={{width: "50%"}}>
              <img
                src={WordgalaxyWhite2}
                className="mr-3 h-9 sm:h-20 inline-block w-full"
                style={{height: "auto"}}
                alt="Logo"
              />
            </div>
            {/*<RingLoader color="rgb(4, 115, 200)" speedMultiplier={0.25} width={"14vw"} />*/}
            <BarLoader color="rgb(4, 115, 200)" speedMultiplier={0.26} width={"14vw"} loading={loadScreenVisible}/>
          </div>
        </Fade>
      )}
      </div>
      <div className="bg-darkBlue main">
        <Header navPath={navPath} resultRef={resultRef} />
        <div className="container mx-auto flex justify-center items-center">
          <div className="text-center pt-1">
            <h2 className="text-white uppercase text-5xl lg:text-8xl font-bold py-5 logo_img_sec">
              <a href="/">
                <img
                  src={WordgalaxyWhite}
                  className="mr-3 h-9 sm:h-12 logo_img"
                  alt="Logo"
                />
              </a>
            </h2>
            <div className="pb-5 pt-2">
              <p className="uppercase text-white text-xl lg:text-4xl pb-2 transition">
                {texts[currentIndex]}
              </p>
              <p className="text-[#ffe99f] text-xl lg:text-2xl py-1 footer-text title-text">
              AI-driven workflows that create high-quality articles and blogs.
              </p>
            </div>
            <div className="py-12 mt-5">
              {/*<Link to="#pricing" onClick={onClick}>*/}
              <Link to="/sign-up" >
                <Button className="capitalize text-2xl mx-auto px-24 bg-lightBluebtn">
                  <p style={{ whiteSpace: 'nowrap' }}>
                    start today
                  </p>
                </Button>
              </Link>
            </div>
            <div className="flex relative">
              <div style={{height: "50vh"}} className="w-full h-64 lg:h-96 video flex justify-center items-center pt-26">
                <ReactPlayer url='https://player.vimeo.com/video/839906980' controls={true} width='100%' height='100%' title="WordGalaxy_Teaser-6-23-2023B" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" pt-24 pb-14 w-full"  style={{backgroundColor: "white"}}>

        <div className="relative overflow-hidden pt-14 pb-2">
          <div className="container mx-auto">
            <div className="my-9">
              <h2 className="uppercase text-2xl md:text-5xl lg:text-6xl font-bold py-5 text-center">
                ONE-CLICK SUCCESS
              </h2>
              <div>
                <p className="text-center text-lg my-5">
                  Use Word Galaxy to create content. 
                </p>
                <p className="text-center text-lg my-5">
                Get unlimited access for just $29.99 USD per month.
                </p>
                <p className="text-center text-lg my-5">
                  Start your journey to success!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="relative overflow-hidden pb-10" id="pricing">
          <div className="container mx-auto">
            
            <div
              className="grid grid-cols-20 gap-5 lg:gap-8 xl:gap-10"
              ref={resultRef}
            >
              {/*<div className="col-span-12 lg:col-span-3">&nbsp;</div>*/}
              <div className="col-span-12 lg:col-span-10 my-5"  >
                  <SubscriptionForm />
              </div>
              {/*<div className="col-span-12 lg:col-span-1">&nbsp;</div>*/}
            </div>
          </div>
        </div>
      </div>
      <div className=" py-10 relative overflow-hidden SEO-section" id="features">
        <div className="container mx-auto pt-14 lg:pt-2">
          <h2 className="text-white uppercase text-2xl lg:text-7xl font-bold py-8 text-center nowrap">
          THE EASIEST CONTENT SOLUTION
          </h2>
          <p className="text-white text-center text-lg mt-3">
          Unlock the full potential of your content plan with Word Galaxy's powerful features.
          </p>
          {/*<Link to="/#pricing" onClick={onClick}>*/}
          <Link to="/sign-up">
            <Button className="capitalize text-2xl mx-auto my-3 px-10 lg:px-24 py-2 mt-10 bg-lightBluebtn">
              Get Started
            </Button>
          </Link>
          <div className="grid grid-cols-12 gap-5 lg:gap-10 py-10">
            {cardsData?.map((item) => (
              <div
                key={item.id}
                className="col-span-12 lg:col-span-6 xl:col-span-4"
              >
                <SEOCard cardTitle={item.title} cardText={item.text} />
              </div>
            ))}
          </div>
          <h2 className="bg-white uppercase text-3xl lg:text-4xl font-bold pb-2 mt-4 pt-12 text-center nowrap">
          SUPPORTED LANGUAGES
          </h2>
          <div className="grid grid-cols-12 p-10 px-12 bg-white rounded text-black mb-4">
            {languagesWithOrder?.map((item) => (
              <div
                key={item.name}
                className="col-span-6 lg:col-span-2 xl:col-span-2"
              >
                <div>{item.name}</div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="bg-gray200 py-10 FAQSection relative overflow-hidden">
        <div className="container mx-auto">
          <h2 className="uppercase text-2xl md:text-4xl lg:text-6xl font-bold py-5 text-center">
            FAQ
          </h2>
          <div className="mx-0 lg:mx-24">
            <FAQs FAQsData={FAQsData} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
