import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "flowbite-react";
import { getAuth, verifyPasswordResetCode } from "firebase/auth";
import ResetPassword from '../ResetPassword'

const HandleResetPassword = ({ actionCode, continueUrl, lang }) => {

	// Localize the UI to the selected language as determined by the lang parameter.

	const auth = getAuth()
	const navigate = useNavigate()
	const [loading, setLoading] = React.useState(true)
	const [accountEmail, setAccountEmail] = React.useState("")
	const [codeError, setCodeError] = React.useState("")

	const verifyCode = async () => {
		console.log("Verifying code")
		try {
			// Verify the password reset code is valid.
		 	let email = await verifyPasswordResetCode(auth, actionCode)
		 	console.log("Set email: ", email)
		 	setAccountEmail(email)
		}
		catch (error) {
			// Invalid or expired action code. Ask user to try to reset the password again.
			console.log("Error verifying code", error)
			setCodeError("Please try to reset your password again. The link is invalid or expired.")
		}

		setLoading(false)
	}

	useEffect(() => {
		verifyCode()
	}, [])

	const errorAndResetButton = (message) => {
		return (
			<div className="flex-col" style={{alignItems: "center"}}>
				<div className="m-4 text-center">{message}</div>
				<Link to="/forgot-password">
					<Button className="capitalize text-2xl lg:mx-auto bg-lightBluebtn text-white my-3 md:my-0">
						Reset Password
					</Button>
				</Link>
			</div>
		);
	}

	// Show the reset screen with the user's email and ask the user for the new password.
	const passwordResetForm = () => {
		
		if (loading) {
			return "Loading"
		}
		else if (codeError) {
			return errorAndResetButton(codeError)
		}
		else if (accountEmail === "") {
			return errorAndResetButton("Please try to reset again. The email address was empty.")
		}
		else {
			return <ResetPassword actionCode={actionCode} accountEmail={accountEmail} />
		}
	}
	
	return (
		<div>{passwordResetForm()}
		</div>
	);
};

export default HandleResetPassword;
