import { Button } from "flowbite-react";
import React, { useState } from "react";
import { ArticleLength, InputWithIcon, Sidebar } from "../../Components";
import { Dropdown } from "../../Components";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faList } from "@fortawesome/free-solid-svg-icons";
import { GeneratorSimple } from './editor-quick'
import { ArticleModes } from './defs.js'
import FaqModal from './faqModal.js'
import ErrorModal from './errorModal.js'
import TutorialModal from './tutorialModal.js'
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";

const SidebarData = [
  {
    id: 0,
    icon: faList,
    text: "Quick Article",
  },
  {
    id: 1,
    icon: faList,
    text: "Custom Article",
  },
];


const SidebarDataBottom = [
  {
    id: 2,
    icon: faList,
    text: "Tutorial Video",
  },
  {
    id: 3,
    icon: faList,
    text: "FAQ",
  },
];


const ArticleEditor = ({ user, loading, auth }) => {

  const [active, setActive] = useState(0);
  const [articleModeId, setArticleModeId] = useState(0)
  const [openFaq, setOpenFaq] = React.useState(false)
  const [openError, setOpenError] = React.useState(false)
  const [openTutorialVideo, setOpenTutorialVideo] = React.useState(false)

  const handleCloseError = () => {
    setOpenError(false)
  }

  const handleCloseFaq = () => {
    setOpenFaq(false)
  }

  const handleCloseTutorial = () => {
    setOpenTutorialVideo(false)
  }

  const handlerActive = (value) => {
    
    setActive(value);

    if (Object.values(ArticleModes).map((v) => v.id).includes(value)) {
      setArticleModeId(value)
    }
    else if (value === 2) {
      setOpenTutorialVideo(!openTutorialVideo)
    }
    else if (value === 3) {
      // FAQ page
      setOpenFaq(!openFaq)
    }
  };

  return (
    <div className="flex-row" style={{	backgroundColor: 'white', position: "relative"}}>
      {<div className="flex" style={{width: "17vw", position: "fixed"}}>
        <Sidebar
          active={active}
          handleActive={handlerActive}
          SidebarData={SidebarData}
          SidebarDataBottom={SidebarDataBottom}
        />
      </div>}
      <div style={{display: "flex", width: "17vw"}}>
      </div>
      <GeneratorSimple mode={articleModeId} loading={loading} user={user} auth={auth} setOpenQuotaError={setOpenError} />
      <FaqModal open={openFaq} handleClose={handleCloseFaq} />
      <ErrorModal open={openError} handleClose={handleCloseError} />
      <TutorialModal open={openTutorialVideo} handleClose={handleCloseTutorial} />
    </div>
  );
};

export default ArticleEditor;
