const PrivacyPolicy = ({ className }) => {

	const styling = className ? className : "color-link px-1"

	return (
		<a href="privacy-policy.pdf" className={styling} target="_blank">
			Privacy Policy
		</a>
	);
}

export default PrivacyPolicy