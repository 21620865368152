import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material' 
import { Link } from '@mui/material';
import ReactPlayer from 'react-player/vimeo'

/* */
export default function TutorialModal(params) {
	return (
		<div style={{textAlign: "right"}}>
			<Dialog open={params.open} onClose={params.handleClose} maxWidth={"100%"}>
				<DialogContent sx={{width: "80vw"}}>
					<DialogContentText>
						<div style={{height: "80vh"}} className="w-full flex justify-center items-center">
							<ReactPlayer url='https://player.vimeo.com/video/840478485' controls={true} width='100%' height='100%' title="WordGalaxyAI Tutorial" />
						</div>
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}