
/* For article creation */
const StatusCodes = {
  error: -1,
  new: 0, 
  started: 1,
  finished: 2
}

const subscriptionNameToId = {
  basic: 0,
  enterprise: 1,
  masterclass: 2
}

const ArticleModes = {
	quick: { id: 0, name: "Quick Workflow"},
	custom: { id: 1, name: "Custom Workflow"}
}

module.exports = {
  StatusCodes,
  subscriptionNameToId,
  ArticleModes
}
