import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashboardNav } from "../../Components";
import Notify from "simple-notify";
import { firestore } from './../../firebaseService';
import "simple-notify/dist/simple-notify.min.css";
import { collection, doc, getDoc, getDocs, query, orderBy, startAt, limit, where, startAfter, deleteDoc } from "firebase/firestore";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileArrowDown, faTrash, faFileWord, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { exportTextWithHtmlAsDocX } from '../../js/utils/html-utils'

const Documents = ({ user, loading, auth }) => {

	const nArticlesFetchInitial = 5
	const nArticlesFetchNext = 25

	const [subscriptions, setSubscriptions] = useState([]); // TODO ***********************************************************
	const navigate = useNavigate()
	const displayName = user && user.displayName ? user.displayName : ""
	const welcomeMessage = displayName === "" ? "" : `Welcome ${displayName}.`
	const [headerKey, setHeaderKey] = useState(0);
	const [hasMoreArticles, setHasMoreArticles] = useState(true)
	const [queried, setQueried] = useState(false)
	const [lastVisibleArticle, setLastVisibleArticle] = useState()

	const [recentArticles, setRecentArticles] = useState([]);
	//useState([{status: 2, keyword: 'apples', json: ""}, {status: 2, keyword: 'oranges', json: ""}, {status: 2, keyword: 'grapes', json: ""}]);

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
		fetchRecentArticles()
	}, [user, loading])

	const articleToFileName = (article, extension) => {
		
		let fileName = article.keyword
			.replace(/[^a-zA-Z0-9 ]/g, '')
			.replace(/ /g, '_')
			.substring(0, 25)

		if (fileName === undefined || fileName.trim() === "") fileName = "article"

		return fileName + extension
	}

	const handleDownloadPlaintext = (article) => {
		try {
			const str = JSON.parse(article.json).text;
			let dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(str);
			let fileName = articleToFileName(article, ".txt")
			
			var dlAnchorElem = document.getElementById('downloadElement');
			dlAnchorElem.setAttribute("href", dataStr);
			dlAnchorElem.setAttribute("download", fileName);
			dlAnchorElem.click();
		}
		catch (error) {
			downloadError(error)
		}
	}

	const handleDownloadDocX = (article) => {
		try {
			const str = JSON.parse(article.json).text;
			var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(str);
			let fileName = articleToFileName(article, "")
			exportTextWithHtmlAsDocX(str, fileName)
		}
		catch (error) {
			downloadError(error)
		}
	}

	const downloadError = (error) => {
		new Notify({
			status: "error",
			title: "Error downloading.",
			text: error.message,
			effect: "fade",
			speed: 300,
			customClass: null,
			customIcon: null,
			showIcon: false,
			showCloseButton: true,
			autoclose: true,
			autotimeout: 5000,
			gap: 20,
			distance: 20,
			type: 1,
			position: "right top",
		});
	}

	const handleDelete = async (article) => {
		//console.log(article)
		try {
			await deleteDoc(doc(firestore, "articles-completed", article.id));

			// Remove from gui
			const remaining = recentArticles.filter((a) => { return a.id !== article.id })
			setRecentArticles(remaining)

			new Notify({
				status: "success",
				title: "",
				text: "Successfully deleted.",
				effect: "fade",
				speed: 300,
				customClass: null,
				customIcon: null,
				showIcon: false,
				showCloseButton: true,
				autoclose: true,
				autotimeout: 1600,
				gap: 20,
				distance: 20,
				type: 1,
				position: "right top",
			});
		}
		catch (error) {
			console.log("Error deleting doc: ", error)
			new Notify({
				status: "error",
				title: "Error deleting.",
				text: error.message,
				effect: "fade",
				speed: 300,
				customClass: null,
				customIcon: null,
				showIcon: false,
				showCloseButton: true,
				autoclose: true,
				autotimeout: 4000,
				gap: 20,
				distance: 20,
				type: 1,
				position: "right top",
			});
		}
	}

	const fetchRecentArticles = async () => {

		if (user && user.hasOwnProperty('uid')) {

			if (!queried || (hasMoreArticles && lastVisibleArticle !== undefined)) {

				//console.log(`Lookup articles, user ${user.uid}`)
				const articlesCompletedRef = collection(firestore, "articles-completed");

				let q;
				let nFetch = queried ? nArticlesFetchNext : nArticlesFetchInitial

				if (!queried) q = query(articlesCompletedRef, where('uid', '==', user.uid),
					orderBy("createdAt", "desc"), limit(nFetch));
				else q = query(articlesCompletedRef, where('uid', '==', user.uid),
					orderBy("createdAt", "desc"), startAfter(lastVisibleArticle), limit(nFetch));

				const documentSnapshots = await getDocs(q);

				const ds = documentSnapshots.docs
				if (ds.length > 0) setLastVisibleArticle(ds[ds.length - 1]);
				else setLastVisibleArticle(undefined)
				//console.log("Docs: ", documentSnapshots)
				const texts = ds.map((doc) => doc.data())
				// Copy Id into doc in case delete by user
				let i = 0
				ds.forEach((doc) => {
					texts[i].id = doc.id
					i += 1
				})
				setQueried(true)
				if (texts.length < nFetch) setHasMoreArticles(false);
				setRecentArticles([...recentArticles, ...texts])
			}
		}
	}

	const timestampToDate = (timestampStr) => {
		const timestamp = parseInt(timestampStr, 10)
		const date = new Date(timestamp);
		// const humanReadableDate = date.toLocaleString().split("/").slice(0, 2).join("/").replace(/\//g, " / ");
		const humanReadableDate = date.toLocaleString()
		return humanReadableDate
	}

	// <div style={{ cursor: "pointer" }}>
	const recentArticleSection = recentArticles.length === 0 ? "" : (
		<div>
			{/*<h2 className="text-xl font-medium py-4 px-4 mt-6">Recent Documents</h2>*/}
			<div className="grid grid-cols-12 gap-5 md:gap-3 p-3 pt-5 bg-blacklight rounded">
				{recentArticles.length === 0 ? "No recent documents found." :
					recentArticles.map((article) =>
						<>
							<div className="col-span-8 lg:col-span-7 article-row rounded hover:cursor-pointer" >
								<p className="capitalize text-color-white px-2 py-2">
									{article.keyword}
								</p>
							</div>
							<div className="col-span-1">
							</div>
							<div className="col-span-2 px-2 flex justify-end items-center hover:cursor-default">
								{timestampToDate(article.createdAt).split(',')[0]}
							</div>
							<div className="col-span-2 flex justify-center items-center">
								<FontAwesomeIcon 
									icon={faFileWord} 
									title="Download .docx"
									color="#fff" 
									className="hover:cursor-pointer" 
									size="lg" 
									onClick={() => { handleDownloadDocX(article) }} />
								<FontAwesomeIcon 
									icon={faFileLines} 
									title="Download .txt"
									color="#fff" 
									className=" hover:cursor-pointer px-4" 
									size="lg" 
									onClick={() => { handleDownloadPlaintext(article) }} />
								<FontAwesomeIcon 
									icon={faTrash} 
									title="Delete"
									color="#fff" 
									className="hover:cursor-pointer" 
									size="lg" 
									onClick={() => { handleDelete(article) }} />
							</div>
						</>
					)}
			</div>
		</div>
	)

	const loadMoreButton = //recentArticles.length < nArticlesFetchInitial || !hasMoreArticles ? "" : 
		<div className="my-5 flex-row" style={{ justifyContent: "flex-end" }}>
			<Button className="capitalize text-2xl lg:mx-auto bg-lightBluebtn text-white my-3 md:my-0"
				disabled={recentArticles.length < nArticlesFetchInitial || !hasMoreArticles}
				onClick={fetchRecentArticles}
			>
				Load more
			</Button>
		</div>

	return (
		<div>
			<DashboardNav subscriptions={subscriptions} key={headerKey} loading={loading} user={user} auth={auth} />
			<a id="downloadElement" style={{ display: 'none' }}></a>
			{loading ? "" : <div className="bg-blackdark text-white py-10">
				{/*<div className="container mx-auto px-3 md:px-5 bg-blackmedium">*/}
				<div className="container mx-auto px-3 md:px-5" style={{ minHeight: '85vh'}}>
					{<div className="bg-blacklight">
						<h1 className="text-4xl font-medium p-5 bg-blacktitle rounded">Recent Documents</h1>
						{/*<div className="grid grid-cols-12 gap-5 md:gap-10 py-5 px-4">
							<div className="col-span-18 md:col-span-8 lg:col-span-10 bg-blacklight ">
							</div>
						</div>*/}
					</div>}

					{recentArticleSection}
					{loadMoreButton}
				</div>
			</div>}
		</div>
	);
};

export default Documents;
