import React from "react";
import Home from "./screens/Home";
import "./customStyles/styles.css";
import "./customStyles/loaders.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AccountAuth from "./screens/AccountAuth";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import Login from "./screens/Login";
import SignUp from "./screens/SignUp";
import ThanksPage from "./screens/ThanksPage";
import AboutUS from "./screens/AboutUs";
import ContactUS from "./screens/ContactUs";
import Dashboard from "./screens/Dashboard";
import Documents from "./screens/Documents";
import Subscription from "./screens/Subscription";
import ArticleEditor from "./screens/ArticleEditor";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Products from "./screens/Products";
import Support from "./screens/Support";
import Failed from "./screens/Products/Failed";
import Success from "./screens/Products/Success";
import './css/common.css'

function App() {
  
  const auth = getAuth()
  const [user, loading, error] = useAuthState(auth);

  const navPath = window.location.pathname;
  
  return (
    <Router>
      <div className="transition duration-1000 ease-in-out page-height" id="head">
        <Routes>
          <Route path="/" element={<Home navPath={navPath} />} />
          <Route path="/account-auth" element={<AccountAuth />} />
          <Route path="/login" element={<Login />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/about-us" element={<AboutUS navPath={navPath} />} />
          <Route path="/contact-us" element={<ContactUS navPath={navPath} />} />
          <Route path="/dashboard" element={<Dashboard user={user} loading={loading} auth={auth} />} />
          <Route path="/documents" element={<Documents user={user} loading={loading} auth={auth} />} />
          <Route path="/settings" element={<Subscription user={user} loading={loading} auth={auth} />} />
          <Route path="/article-editor" element={<ArticleEditor user={user} loading={loading} auth={auth} />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          // <Route path="/reset-password" element={<ResetPassword actionCode={""} accountEmail={""}/>} />
          <Route path="/products" element={<Products />} />
          <Route path="/support" element={<Support />} />
          <Route path="/success" element={<Success />} />
          <Route path="/failed" element={<Failed />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
