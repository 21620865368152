import React, { useEffect, useState } from 'react';
import { functions, firestore } from './../../firebaseService';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStripe } from '@stripe/react-stripe-js';
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import './SubscriptionPlan.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from 'flowbite-react';
import { CANCEL_URL, SUCCESS_URL, STRIPE_PUBLIC_KEY, CANCEL_SUBSCRIPTION_URL } from './../../config/env';
import axios from 'axios';
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.min.css";
import moment from 'moment'
import '../../css/common.css'

const SubscriptionForm = () => {

    const stripe = useStripe();
    const navigate = useNavigate();
    const [loading, setLoading] = useState({});
    const [removeLoading, setRemoveLoading] = useState({});
    const [products, setProducts] = useState([]);
    const auth = getAuth();
    const [user, error] = useAuthState(auth);
    const [searchParams] = useSearchParams();
    const [subscriptions, setSubscriptions] = useState([]);
    const [subscriptionsPlan, setSubscriptionsPlan] = useState([]);
    const [payments, setPayments] = useState([]);
    const [paymentsPlan, setPaymentsPlan] = useState([]);
    const [pathName, setPathName] = useState('');
    const [productKey, setProductKey] = useState(0);

    const fetchProducts = async () => {
        try {
            const collectionRef = firestore.collection('products');
            const snapshot = await collectionRef.where('active', '==', true).get();

            const productData = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const product = doc.data();
                    product.id = doc.id;

                    const priceSnapshot = await doc.ref.collection('prices').where('active', '==', true).get();
                    const prices = priceSnapshot.docs.map((priceDoc) => ({ id: priceDoc.id, ...priceDoc.data() }));

                    product.prices = prices;

                    return product;
                })
            );
            setProducts(productData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    //get list
    useEffect(() => {

        async function removeSubscriptionIfExist() {
            if (searchParams.get('success') && searchParams.get('productId')) {
                await fetchSubscription();
            }
        }

        removeSubscriptionIfExist();
    }, [searchParams])

    // ??
    // cancel subscription 
    // useEffect(() => {
    //     async function cancelSubscriptionIfExist() {
    //         if (searchParams.get('success') && searchParams.get('productId') && subscriptions?.length > 0) {
    //             const data = await handleRemoveSubscribedPlan();
    //             if (data) {
    //                 navigate('/subscription');
    //             }
    //         }
    //     }

    //     cancelSubscriptionIfExist();
    // }, [searchParams, subscriptions])

    useEffect(() => {
        setPathName(window?.location?.pathname.split('/')[1]);
        fetchProducts();

        if (user) {
            fetchSubscription();
            fetchPayments();
        } else {
            setSubscriptions([]);
        }
    }, [user]);

    const fetchSubscription = async () => {

        try {
            const snapshot = await firestore
                .collection("customers")
                .doc(user?.uid)
                .collection("subscriptions").where('status', '==', 'active').get();

            const subscribeData: any = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const subscribe = doc.data();
                    subscribe.id = doc.id;
                    return subscribe;
                })
            );

            const planData = await subscribeData.map(item => item.items[0]?.price?.id);
            await setSubscriptionsPlan(planData);
            await setSubscriptions(subscribeData);
            setProductKey((productKey) => productKey + 1)
            return true;
        } catch (error) {
            console.error('Error fetching data:', error);
            return false;
        }
    };

    const fetchPayments = async () => {
        try {
            const snapshot = await firestore
                .collection("customers")
                .doc(user?.uid)
                .collection("payments")
                // .where('status', '==', 'succeeded')
                .orderBy("created", "desc")
                .limit(1)
                .get();

            const paymentsData: any = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const payments = doc.data();
                    payments.id = doc.id;
                    return payments;
                })
            );

            setPayments(paymentsData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const handleCancel = async (product: any) => {

    //     try {
    //         setRemoveLoading((prevLoading) => ({ ...prevLoading, [product.id]: true }));

    //         const subscriptionId = subscriptions[0]?.id;
    //         try {

    //             const response = await axios.post(CANCEL_SUBSCRIPTION_URL, { subscriptionId });
    //             console.log('Delete success: ' + JSON.stringify(response.data));
    //             new Notify({
    //                 status: "success",
    //                 title: "Subscription Canceled",
    //                 text: `Subscription has been canceled successfully`,
    //                 effect: "fade",
    //                 speed: 300,
    //                 showIcon: false,
    //                 showCloseButton: true,
    //                 autoclose: true,
    //                 autotimeout: 10000,
    //                 gap: 40,
    //                 distance: 40,
    //                 type: 1,
    //                 position: "right top",
    //             });

    //             setRemoveLoading(false)
    //             const restOfData = subscriptions.filter(item => item.id !== subscriptionId);
    //             setSubscriptions(restOfData);

    //             const planData: any = await restOfData.map((item: any) => item?.items[0]?.price?.id);
    //             await setSubscriptionsPlan(planData);
    //         } catch (error) {
    //             new Notify({
    //                 status: "error",
    //                 title: "Subscription Canceled",
    //                 text: `Canceling subscription has failed`,
    //                 effect: "fade",
    //                 speed: 300,
    //                 showIcon: false,
    //                 showCloseButton: true,
    //                 autoclose: true,
    //                 autotimeout: 10000,
    //                 gap: 40,
    //                 distance: 40,
    //                 type: 1,
    //                 position: "right top",
    //             });
    //             console.log('Cancel failed', error);
    //             setRemoveLoading(false)
    //         }

    //     } catch (error) {
    //         console.log({ error });
    //         setRemoveLoading(false)
    //     }

    // }

    const handleCheckout = async (product) => {
        try {
            if (!user) return navigate("/sign-up?redirect=subscription");
            setLoading((prevLoading) => ({ ...prevLoading, [product.id]: true }));
            // const priceId = product?.prices?.[0]?.id;
            const priceId = product?.prices?.[0]?.id //product?.prices?.[0]?.id;

            new Notify({
                status: "success",
                title: "Starting checkout.",
                text: `Starting checkout.`,
                effect: "fade",
                speed: 300,
                showIcon: false,
                showCloseButton: true,
                autoclose: true,
                autotimeout: 5000,
                gap: 40,
                distance: 40,
                type: 1,
                position: "right top",
            });

            const req = {
                price: priceId,
                success_url: `${SUCCESS_URL}/?success=true&productId=${product?.id}&name=${product?.name}`,
                cancel_url: `${CANCEL_URL}/?fail=true`,
                allow_promotion_codes: true
            }

            // Include a referral id if user was referred when signing up
            try {
                const referral = await firestore
                    .collection("referrals")
                    .doc(user.email)
                    .get()

                if (referral.exists && referral.toltReferral !== undefined) {
                    req.metadata = {
                        tolt_referral: referral.toltReferral
                    }
                    console.log("Added metadata for ref: ", req)
                }
                // if (user.customClaims !== undefined && user.customClaims.hasOwnProperty("toltReferral") && user.customClaims.toltReferral !== undefined) {
                //     req.metadata = {
                //         tolt_referral: user.customClaims.toltReferral
                //     }
                // }
            }
            catch (error) {
                console.error('Error adding referral:', error);
            }
            
            const checkoutSessionRef = await firestore
                .collection("customers")
                .doc(user?.uid)
                .collection("checkout_sessions")
                .add(req);

            checkoutSessionRef.onSnapshot(async (snap) => {
                
                const { sessionId } = snap.data();
                if (error) {
                    console.log(error.message);
                    setLoading(false);
                }

                if (sessionId) {
                    await stripe.redirectToCheckout({ sessionId });
                    setLoading(false);
                }
            });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    // const handleRemoveSubscribedPlan = async () => {
    //     const subscriptionId = subscriptions[subscriptions.length - 2]?.id;

    //     try {
    //         if (subscriptionId && subscriptionId !== undefined) {
    //             const response = await axios.post(CANCEL_SUBSCRIPTION_URL, { subscriptionId });
    //             const restOfData = subscriptions.filter(item => item.id !== subscriptionId);
    //             setSubscriptions(restOfData);

    //             const planData: any = await restOfData.map((item: any) => item?.items[0]?.price?.id);
    //             await setSubscriptionsPlan(planData);
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     } catch (error) {

    //         console.log('Delete failed, see console,', error);
    //         return false;
    //     }
    // }

    const formatPrice = (price) => {
        return (price / 100).toFixed(2);
    };

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp?.seconds * 1000);
        return date.toLocaleString();
    };

    return (
        <div>
            <main>
                <div className='' key={productKey}>
                    {pathName === 'dashboard' || pathName === 'settings' ? (
                        <div style={{ 'display': 'block' }}>
                            {products.map((product, index) => (
                                product.prices[0] && (
                                    <div key={index}>
                                        {user && subscriptionsPlan.includes(product?.prices?.[0]?.id) ? (
                                            <h2 className="text-xl font-medium py-4 mt-8 px-4">Your Plan</h2>
                                        ) : (
                                            <div className="py-4 mt-4 px-4"></div>
                                        )}

                                        <div className="items-center flex-wrap lg:flex-nowrap py-2 px-4 bg-blacktitle rounded">
                                            <div className="flex justify-between items-center flex-wrap lg:flex-nowrap p-3 mt-3 bg-blacktitle rounded px-9">
                                                <div className='text-title'>{product.name}</div>
                                                {user && subscriptionsPlan.includes(product?.prices?.[0]?.id) ? (
                                                    <div className='flex justify-end'>
                                                        <p className='current-plan'>Subscribed</p>
                                                       {/* <button
                                                            className='ml-5 cancel-plan'
                                                            onClick={() => handleCancel(product)}
                                                            disabled={!stripe || removeLoading[product.id]}
                                                        >
                                                            {removeLoading[product.id] ? 'Canceling...' : 'Cancel Plan'}
                                                        </button>*/}
                                                    </div>
                                                ) : (
                                                    <Button
                                                        className="capitalize bg-lightBluebtn text-white my-3 lg:my-0 px-2 py-1 rounded"
                                                        onClick={() => handleCheckout(product)}
                                                        disabled={!stripe || loading[product.id]}
                                                    >
                                                        {loading[product.id] ? 'Subscribing...' : 'Subscribe'}
                                                    </Button>
                                                )}
                                            </div>
                                            <div className='block mx-10 mb-10 mt-10'>
                                                <div className='flex mb-4 items-center'>
                                                    <div className='amount text-white'>
                                                        ${formatPrice(product.prices?.[0]?.unit_amount)}
                                                    </div>
                                                    <div className="interval ml-2">
                                                        per {product.prices?.[0]?.interval}
                                                    </div>
                                                </div>
                                                <div className='include-container mt-5 text-color-white'>
                                                    <h4>This Includes:</h4>
                                                    <span className='m-1'>
                                                        {product.metadata?.features?.split(',').map((item, index) => (
                                                            <div key={index}>
                                                                <FontAwesomeIcon icon={faCheckCircle} color="rgb(15, 126, 248)" className="px-2" />
                                                                {item}
                                                            </div>
                                                        )) ?? ""}
                                                    </span>
                                                </div>
                                                {/*<div className='mt-2'>
                                                    {user && subscriptionsPlan.includes(product?.prices?.[0]?.id) && (
                                                        <div>
                                                            <div className="py-2">
                                                                <span className='detail-title'>
                                                                    Details
                                                                </span>
                                                            </div>
                                                            <hr className="details-break" />
                                                            {subscriptions.map((subscription) => {
                                                                return (
                                                                    <div>
                                                                        {subscription?.status === 'active' && <div className='display-block'>
                                                                            <table className='subscription-table'>
                                                                                <tr>
                                                                                    <td>
                                                                                        Subscription ID:
                                                                                    </td>
                                                                                    <td>
                                                                                        {subscription?.id}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>
                                                                                        Subscription Start Date:
                                                                                    </td>
                                                                                    <td>
                                                                                        {formatTimestamp(subscription?.current_period_start)}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Subscription End Date:
                                                                                    </td>
                                                                                    <td>
                                                                                        {formatTimestamp(subscription?.current_period_end)}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td >
                                                                                        Status:
                                                                                    </td>
                                                                                    <td className='active-status'>
                                                                                        {subscription.status}
                                                                                    </td>
                                                                                </tr>
                                                                            </table>

                                                                        </div>}
                                                                    </div>
                                                                )
                                                            })
                                                            }
                                                            <br />
                                                            {payments.length > 0 && 
                                                                <div className="mt-4 py-2">
                                                                    <span className='detail-title'>
                                                                        Payment Details
                                                                    </span>
                                                                    <hr className="details-break"/>
                                                                </div>
                                                            }
                                                            {payments.map((payment) => {
                                                                return (
                                                                    <div>
                                                                        {payment?.status === 'succeeded' && <div className='display-block'>
                                                                            <table className='payment-table'>
                                                                                <tr>
                                                                                    <td>
                                                                                        Payment ID:
                                                                                    </td>
                                                                                    <td>
                                                                                        {payment?.id}
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <td>
                                                                                        Payment Date:
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            moment.unix(payment?.created).format('L')
                                                                                        },{'  '}
                                                                                        {
                                                                                            moment.unix(payment?.created).format('LTS')
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Payment Amount:
                                                                                    </td>
                                                                                    <td>
                                                                                        {payment?.amount}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>
                                                                                        Status:
                                                                                    </td>
                                                                                    <td>
                                                                                        {payment.status}
                                                                                    </td>
                                                                                </tr>
                                                                            </table>

                                                                        </div>}
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )}
                                                </div>*/}
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    ) : (
                        <div className="plans-container subscription-form">
                            {products.map((product, index) => (
                                product.prices[0] && (
                                    <div
                                        className={`${product.name === 'Individual Plan' ? "plan individual-plan" : pathName === 'dashboard' ? "dashboard-plan plan" : "plan"}`}
                                        key={index}
                                    >
                                        <h2 className="text-center">{product.name}</h2>
                                        <div className='flex mt-10 mb-3 items-center justify-center'>
                                            <div className='amount'>
                                                ${formatPrice(product.prices?.[0]?.unit_amount)}
                                            </div>
                                            <div className="interval ml-1">
                                                per {product.prices?.[0]?.interval}
                                            </div>
                                        </div>

                                        {user && subscriptionsPlan.includes(product?.prices?.[0]?.id) && subscriptions[0]?.status === 'active' ? (
                                            ''
                                        ) : (
                                            <button
                                                className='subscribe-btn'
                                                onClick={() => handleCheckout(product)}
                                                disabled={!stripe || loading[product.id]}
                                            >
                                                {loading[product.id] ? 'Subscribing...' : 'Subscribe'}
                                            </button>
                                        )}
                                        {user && subscriptionsPlan.includes(product?.prices?.[0]?.id) ? (

                                            <div className='display-flex'>
                                                <p className='current-plan'>Subscribed</p>
                                            </div>
                                        ) : ''}

                                       {/* {user && subscriptionsPlan.includes(product?.prices?.[0]?.id) ? (
                                            <div className='flex justify-end'>
                                                <button
                                                    className='cancel-plan'
                                                    onClick={() => handleCancel(product)}
                                                    disabled={!stripe || removeLoading[product.id]}
                                                >
                                                    {removeLoading[product.id] ? 'Canceling...' : 'Cancel Plan'}
                                                </button>
                                            </div>
                                        ) : ('')}*/}
                                        <div className='include-container mt-5'>
                                            <h4>This Includes:</h4>
                                            <span className='m-1'>
                                                {product.metadata?.features?.split(',').map((item, index) => (
                                                    <div key={index} className='include-box'>
                                                        <FontAwesomeIcon icon={faCheckCircle} color="rgb(15, 126, 248)" className="px-2" />
                                                        {item}
                                                    </div>
                                                )) ?? ""}
                                            </span>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    )}
                </div>
            </main>
        </div>
    );
};

export default SubscriptionForm;
