import React from "react";
import { Button } from "flowbite-react";
import { AboutBanner } from "../../assets";
import { Header } from "../../Components";
import { Footer } from "../../Components";
import { Link } from "react-router-dom";
import '../../css/common.css'
import ReactPlayer from 'react-player/vimeo'

const AboutUS = ({ navPath }) => {
  return (
    <div className="dark-screen">
      <Header navPath={navPath} />
      <div className="container mx-auto">
        <div className="text-center py-10 w-10/12 mx-auto">
          <h1 className="text-5xl font-bold py-3 mb-10 text-color-white">About Us</h1>
          <p className="text-xl leading-10 text-white">
            WordGalaxy is run by bloggers, SEOs, and NLP developers. 
            We work to develop groundbreaking AI tools that make you more efficient and assist in your workflows.
            {/*make professionals and businesses more efficient by assisting in your workflows.*/}
            {/*We're inspired by emerging solutions that leverage AI, and we work to improve the efficiency of professionals and businesses alike, 
            by developing groundbreaking AI tools to assist your workflow. */}
            {/*This is the what gets us out of the bed in the morning!*/}
          </p>
          <div className="relative mt-6">
            <div className="flex relative mb-16">
              <div style={{height: "50vh"}} className="w-full h-64 lg:h-96 video flex justify-center items-center">
                <ReactPlayer url='https://player.vimeo.com/video/839906980' controls={true} width='100%' height='100%' title="WordGalaxy_Teaser-6-23-2023B" />
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-12 gap-8 py-16 px-5 relative">
          <div className="col-span-8">
            <p className="text-md text-color-white py-2">
              We're a team of SEO specialists and NLP (Natural Language
              Processing) developers.
            </p>
            <p className="text-md text-color-white py-2">
              The availability of new models, such as ChatGPT, has enabled
              creators the ability to make content like never before. We've
              taken this opportunity to combine our experience into creating the
              world's best content creation tools available.
            </p>
            <p className="text-md text-color-white py-2">
              As a part of the greater SEO community, we stay connected. 
              The community has provided an enormous amount of great feedback regarding the development of our tools and solutions.
              We're thankful for you, and we'll continue to listen to your ideas, and implement the changes we all want to
              see!
            </p>
            <p className="text-md text-color-white py-2">
              If you want to be a part of the WordGalaxy team, please reach out
              to us at support@wordgalaxy.ai
            </p>
          </div>
          <div className="col-span-4 flex justify-center items-center box-css">
            <img src={AboutBanner} width="60%" />
          </div>
        </div>
        <div className="relative z-10">
          <div className="seo-box box-css"></div>
          <div className="flex-col bg-bluebutton p-10 my-10 mb-24 rounded-xl text-center">
            <h3 className="text-2xl text-white">
              Start Today - SEO Optimized Content
            </h3>
            <p className="text-white text-md mt-5 mb-5 py-5">
              Who knew creating amazing content could be so easy? Our workflows
              are designed to keep your website's content plan moving forward.
              Whether it's our Quick Article Workflow or the Custom Article
              Workflow, you'll be able to scale like never before.
            </p>
            <Link to="/sign-up">
              <Button className="rounded capitalize px-5 mx-auto" style={{backgroundColor: "#5399ff"}}>
                <span className="">start today</span>
              </Button>
            </Link>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUS;
