import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "flowbite-react";
import { getAuth, checkActionCode, applyActionCode, sendPasswordResetEmail } from "firebase/auth";

/*
Revoking email address changes—when users change their accounts' primary email addresses, 
Firebase sends an email to their old addresses that allow them to undo the change
*/
const HandleRecoverEmail = ({ actionCode, lang }) => {

	// Localize the UI to the selected language as determined by the lang

	const auth = getAuth()
	const navigate = useNavigate()
	const [loading, setLoading] = React.useState(true)
	const [restoredEmail, setRestoredEmail] = useState("")
	const [codeError, setCodeError] = React.useState("")

	const verifyCode = async () => {
		
		console.log("Verifying code")
		
		try {
			// Verify the action code is valid.

			// Confirm the action code is valid.
			let info = await checkActionCode(auth, actionCode)
			//let info = {data: { email: "test@example.com"}}

			// Get the restored email address.
			setRestoredEmail(info['data']['email'])
			
			console.log("restored email: ", info.data.email)

			// Revert to the old email.
			await applyActionCode(auth, actionCode);
			
			// Account email reverted to restoredEmail
			setLoading(false)

			/*
		    // Might also want to give the user the option to reset their password
		    // in case the account was compromised:
		    sendPasswordResetEmail(auth, restoredEmail).then(() => {
		      // Password reset confirmation sent. Ask user to check their email.
		    }).catch((error) => {
		      // Error encountered while sending password reset code.
		    });
		    */
		}
		catch (error) {
			// Invalid or expired action code. Ask user to try to reset the password again.
			//console.log("Error verifying code", error)
			setCodeError("Please try to restore your email again. The link is invalid or expired.")
		}

		setLoading(false)
	}

	useEffect(() => {
		verifyCode()
	}, [])

	const errorAndButton = (message) => {
		return (
			<div className="flex-col" style={{alignItems: "center"}}>
				<div className="m-4 text-center">{message}</div>
				<Link to="/dashboard">
					<Button className="capitalize text-2xl lg:mx-auto bg-lightBluebtn text-white my-3 md:my-0">
						Return to Dashboard
					</Button>
				</Link>
			</div>
		);
	}

	// Show the reset screen with the user's email and ask the user for the new password.
	const content = () => {
		
		if (loading) {
			return "Loading"
		}
		else if (codeError) {
			return errorAndButton(codeError)
		}
		else {

			setTimeout(function(){
				navigate('/dashboard')
			}, 8000)

			const msg = `Email was successfully reset to:`

			return (
				<div className="flex-col text-center my-5" style={{alignItems: "center"}}>
					{msg}
					<br></br>
					{restoredEmail}
					<br></br>
					<br></br>
					You will be redirected in a few seconds.
				</div>
			)
		}
	}
	
	return (
		<div>{content()}
		</div>
	);
};

export default HandleRecoverEmail;
