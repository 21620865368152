import React from 'react';
const Success = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const subscriptionId = urlParams.get('subscription_id');
    console.log({subscriptionId});
    
    return (
        <div>
            Success
        </div>
    );
};

export default Success;
