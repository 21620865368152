import React, { useEffect, useState } from "react";
import { Button, Dropdown, Navbar } from "flowbite-react";
import InputWithIcon from "../InputWithIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faSearch,
	faFileCirclePlus,
	faGraduationCap,
	faHome
} from "@fortawesome/free-solid-svg-icons";
import { WordgalaxyWhite } from "../../assets";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
// import { useAuthState } from "react-firebase-hooks/auth";
import { firestore } from './../../firebaseService';

const DashboardNav = ({ subscriptions, loading, user, auth }) => {
	
	// const [subscriptions, setSubscriptions] = useState([]);
	const [headerKey, setHeaderKey] = useState(0);
	// const auth = getAuth();
	// const [user, loading, error] = useAuthState(auth);
	const navigate = useNavigate()

	const signOutUser = () => {
		signOut(auth).then((res)=>{
			//console.log('Signed out.', res);
		}).catch((err)=>{
			console.warn('signOut error', err);
		})
	}

	const path = window.location.pathname

	const navigateEditor = () => {
		if (!path.startsWith("article-editor")) navigate('/article-editor')
	}

	const navigateSettings = () => {
		if (!path.startsWith("settings")) navigate('/settings')
	}

	const navigateSupport = () => {
		if (!path.startsWith("support")) navigate('/support')
	}

	const homeIcon = <FontAwesomeIcon
		icon={faHome}
		color="#fff"
		// color="rgb(23, 129, 241)"
		className="mr-2"
		size="xl"
	/>

	const logo = <img
		src={WordgalaxyWhite}
		className="mx-3 h-14 sm:8 "
		alt="Logo"
	/>

	return (
		<Navbar fluid={false} rounded={false} className="bg-blackmedium text-white border-none">
		<Navbar.Toggle />
		<Navbar.Collapse style={{ width: "100%" }} key={headerKey}>
			<div
				className="block md:flex justify-between items-center"
				style={{ width: "100%" }}
			>
				<div className="w-12/12 lg:w-1/3">
					<ul className="block md:flex items-center">
						<li className="mx-3 cursor-pointer">
							
						{path.startsWith("/dashboard") ? homeIcon : 
							<a href="/dashboard" className="border-none">
								{homeIcon}
							</a>}
						</li>
						<li className="mx-3 cursor-pointer">
							{path.startsWith("/documents") ? "Documents" : 
								<a href="/documents" className="border-none">Documents</a>}
						</li>
						<li className="mx-3 cursor-pointer">
							{path.startsWith("/settings") ? "Settings" : 
								<a href="/settings" className="border-none">Settings</a>}
						</li>
						<li className="mx-3 cursor-pointer">
							<a href="https://wordgalaxy.tolt.io/" className="border-none" target="_blank">Affiliate</a>
						</li>
					</ul>
				</div>
				<div className="w-12/12 lg:w-1/3">
					{path.startsWith("/dashboard") ? logo : 
						<a href="/dashboard" className="border-none">
							{logo}
						</a>
					}
				</div>
				<div className="block md:flex items-center justify-end w-12/12 py-2 ml-0 lg:ml-16"
					disabled={true}
				>	
					{/*{!subscriptions.length > 0 ?*/}
					{false ?
						<Button className="capitalize text-2xl lg:mx-auto bg-lightBluebtn text-white" disabled={true} style={{ cursor: "pointer" }}>
							<FontAwesomeIcon
								icon={faFileCirclePlus}
								color="#fff"
								className="mr-2"
								size="lg"
							/>
							New Article
						</Button> :
						<Link to="/article-editor">
							<Button className="capitalize text-2xl mx-0 lg:mx-auto bg-lightBluebtn text-white my-3 md:my-0" >
								<FontAwesomeIcon
									icon={faFileCirclePlus}
									color="#fff"
									className="mr-2"
									size="lg"
								/>
								New Article
							</Button>
						</Link>
					}
					<div className="mx-3 flex items-center my-3 md:my-0">
						{/*data-dropdown-trigger="{hover|click}"*/}
						<Dropdown className="bg-blacklight text-white" label="" inline={true}>
							{/*<Dropdown.Item>Dashboard</Dropdown.Item>*/}
							<Dropdown.Item onClick={navigateEditor}>Editor</Dropdown.Item>
							<Dropdown.Item onClick={navigateSettings}>Settings</Dropdown.Item>
							<Dropdown.Item onClick={navigateSupport}>Support</Dropdown.Item>
							<Dropdown.Item onClick={signOutUser}><Link to="/" className="border-none">Sign out</Link></Dropdown.Item>
						</Dropdown>
					</div>
				</div>
			</div>
		</Navbar.Collapse>
	</Navbar>
	);
};

export default DashboardNav;
