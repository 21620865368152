export const sampleKeywords = `
Keto Trail Mix
Ultimate Guide
Healthy Snack
Benefits of keto trail mix
Make your own
Creative ways to enjoy
Low-carb diets
Power Up with Keto Trail Mix
Satisfying Snack
Power of Keto Trail Mix
Keto Diet
Ketogenic diet
High amounts of healthy fats
Moderate amounts of protein
Low carbohydrates
Weight loss
Good health
Sugary foods
Grains
Dietary plan
Trail mix
Convenient and nutritious snack
Nuts, seeds, and dried fruits
Healthy fats
Protein and fiber
Source of energy
Slow-digesting carbohydrates
Brief Introduction to Keto
Metabolic state called ketosis
Liver breaks down stored fat
Ketones as fuel
Glucose
Body's metabolism
Weight loss
Improved blood sugar regulation
`