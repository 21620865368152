const TermsOfService = ({ className }) => {

	const styling = className ? className : "color-link px-1"

	return (
		<a href="terms-of-service.pdf" className={styling} target="_blank">
			Terms Of Service
		</a>
	);
}

export default TermsOfService