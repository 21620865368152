import React, { useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";
import { Link } from "react-router-dom";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.min.css";
import { HashLoader } from "react-spinners";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import LoginImage from '../../Components/LoginImage'

const ForgotPassword = () => {
	
	const auth = getAuth()
	const [showPassword, setShowPassowrd] = useState(false);
	const [loading, setLoading] = useState(false);
	const [userInfo, setUserInfo] = useState({
		email: "",
	});

	const [emailSent, setEmailSent] = useState(false)

	const [error, setError] = useState({
		email: "",
	});

	const handleReset = async () => {
		
		if (!userInfo.email) {
			setError({
				email: "Email is required.",
			});
		} 
		else if (
			!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(userInfo.email)
		) {
			setError({
				email: "A valid email address is required.",
			});
		} 
		else {

			setError({
				email: "",
			});
			
			setLoading(true);
			
			let res = {}

			try {
				if (!emailSent) await sendPasswordResetEmail(auth, userInfo.email)
				setEmailSent(true)
			}
			catch (error) {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.error(errorMessage)
				res.error = error
			}
			
			if (!res.error) {
				new Notify({
					status: "success",
					title: "Success",
					text: "A reset link has been sent to your email.",
					effect: "fade",
					speed: 500,
					customClass: null,
					customIcon: null,
					showIcon: false,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3500,
					gap: 20,
					distance: 20,
					type: 1,
					position: "right top",
				});
				
				// window.location.pathname = "/reset-password";
			} 
			else {
				new Notify({
					status: "error",
					title: "Error",
					text: "Something went wrong, please try again. If the issue continues, please refresh the page and / or contact support.",
					effect: "fade",
					speed: 500,
					customClass: null,
					customIcon: null,
					showIcon: false,
					showCloseButton: true,
					autoclose: true,
					autotimeout: 3500,
					gap: 20,
					distance: 20,
					type: 1,
					position: "right top",
				});
			}

			setLoading(false);
		}
	};

	return (
		<div className="grid grid-cols-12 h-full lg:h-screen bg-bluebutton">
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<LoginImage />
			</div>
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<div className="container mx-auto bg-white rounded py-10">
					<h3 className="text-4xl text-center py-2 mt-8">
						Password Reset
					</h3>
					<div className="login_form mx-auto p-6">
						<p className="text-gray100 text-lg text-center">
							Enter the email you used to register. We'll send you a link to reset your password.
						</p>
					</div>
					<div className="flex flex-col gap-4 px-10">
						<div>
							<div className="mb-1 block">
								<Label htmlFor="email" value="E-mail" className="font-normal" />
							</div>
							<TextInput
								id="email"
								type="text"
								sizing="md"
								placeholder="name@example.com"
								style={{
									border: "none",
									backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) =>
									setUserInfo({
										...userInfo,
										email: e.target.value,
									})
								}
							/>
							{error.email ? (
								<span className="text-danger text-xs">{error.email}</span>
							) : null}
						</div>
						<Button
							className="capitalize text-2xl bg-lightBluebtn w-full"
							onClick={handleReset}
							disabled={loading || emailSent}
						>
							{loading ? (
								<HashLoader
									color={"#fff"}
									loading={loading}
									size={20}
									aria-label="Loading Spinner"
									data-testid="loader"
								/>
							) : (
								"Send"
							)}
						</Button>
						<p className="text-md py-2 text-gray100 lg:mx-24 text-center ">
							<a href="/login" className="color-link-dark px-1 mx-1 underline">
								Return to Login
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
