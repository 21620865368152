import React, { useEffect, useState } from "react";
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import NativeSelect from '@mui/material/NativeSelect';
import InputBase from '@mui/material/InputBase';

// How to make this not blue
//& .MuiButtonBase-root-MuiMenuItem-root.Mui-selected

// (62, 72, 78)
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiMenuItem-root': {
    justifyContent: "center"
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    color: "#fff",
    backgroundColor: "rgb(47,47,47)", //theme.palette.background.paper, 
    border: '1px solid #353535',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    textAlign: "center",
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#1c86ff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
}));

const modelVersions = ["gpt-3.5-turbo", "gpt-4"]

/* Let user select which model verison to use */
const ModelVersionSelect = () => {

  const [modelVersion, setModelVersion] = useState("gpt-3.5-turbo")

  useEffect(() => {
    if (localStorage && localStorage.hasOwnProperty("modelVersion") && modelVersions.includes(localStorage.modelVersion)) {
      setModelVersion(localStorage.modelVersion)
    }
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    setModelVersion(event.target.value);
    if (localStorage) localStorage.modelVersion = event.target.value;
  };

  return (
    

    <FormControl sx={{ m: 1 }} variant="standard" fullWidth>
        <InputLabel id="customized-select-label" style={{color: "#1c86ff" }}>Model Version</InputLabel>
        <Select
          labelId="customized-select-label"
          id="customized-select"
          value={modelVersion}
          onChange={handleChange}
          input={<BootstrapInput />}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: 'rgb(38 38 38)', // Set the background color of the dropdown menu
                color: "#fff",
                display: "flex", 
                justifyContent: "center",
              },
            },
          }}
        >
          <MenuItem value={"gpt-3.5-turbo"}>gpt-3.5-turbo</MenuItem>
          <MenuItem value={"gpt-4"}>gpt-4</MenuItem>
        </Select>
      </FormControl>
  );
}

export default ModelVersionSelect
