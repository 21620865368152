import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { Typography } from '@mui/material' 
import { Link } from '@mui/material';

/*
Error message when user gets exceeded quota message from openAI
Likely, they are using the free-tier credit which has no API access available
*/
export default function ErrorModal(params) {
	
	return (
		<div style={{textAlign: "right"}}>
			<Dialog open={params.open} onClose={params.handleClose} maxWidth={"52%"}>
				<DialogContent>
					<DialogContentText sx={{p: 4}}>
					<Typography variant="h5" fontWeight="bold" mb={1}>We're sorry, it looks like your account has an issue.</Typography>
					<Typography mt={4} mb={4}>If using the promotional tier from OpenAI, their free credit does not allow access to the the API.</Typography>
					<Typography mb={4}>It’s an easy fix. Follow this link to <span style={{ fontWeight: "bold" }}>set up a payment method with OpenAI</span> to fund your key: </Typography>
					<Typography mb={4}>
						<Link className="link-blue" href="https://platform.openai.com/account/billing/overview" target="_blank" rel="noopener noreferrer">
						https://platform.openai.com/account/billing/overview
						</Link>
					</Typography>
					<Typography mb={4}>We have a tutorial video at the bottom left of this page if you need more help.</Typography>
					
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</div>
	);
}