import React from "react";
import { Button } from "flowbite-react";
import { AboutBanner } from "../../assets";
import { Header } from "../../Components";
import { Footer } from "../../Components";
import { Link } from "react-router-dom";
import ContactForm from '../../Components/Support/contactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import Notify from "simple-notify";
import "simple-notify/dist/simple-notify.min.css";

const ContactUS = ({ navPath }) => {

	const copiedEmail = () => {
		
		navigator.clipboard.writeText("support@wordgalaxy.ai")

		new Notify({
			status: "",
			// title: "Copied.",
			text: "Copied.",
			effect: "fade",
			speed: 300,
			customClass: null,
			customIcon: null,
			showIcon: false,
			showCloseButton: true,
			autoclose: true,
			autotimeout: 1200,
			gap: 20,
			distance: 20,
			type: 1,
			position: "right top",
		});
	}

  return (
	<div className="dark-screen">
	  <Header navPath={navPath} />
	  <div className="container mx-auto" style={{minHeight: "90vh"}}>
		<div className="text-center py-10 w-10/12 mx-auto px-10">
		  <h1 className="text-5xl font-bold py-3 mb-10 text-white">Contact Us</h1>
		  
		  <div className="flex-row justify-center items-center mb-4">
			  <p 
				className="text-xl leading-10" 
				style={{color: "#e0e0e0"}} 
			  >
			  support@wordgalaxy.ai
			  </p>
			  <FontAwesomeIcon 
				icon={faCopy} 
				title="Copy"
				// color="#007cff" 
				color="gray"
				className="hover:cursor-pointer ml-4" 
				size="lg" 
				onClick={() => copiedEmail()} 
				/>
		  </div>
		  <p className="text-xl leading-10" style={{color: "#e0e0e0"}}>
			Please contact us with any feedback or questions. We're happy to use your suggestions to improve the Article Workflows. 
		  </p>
		</div>

		{/*<div className="flex justify-center">
			<ContactForm sendFeedback={sendFeedback} email={""} />
		</div>*/}

	  </div>
	  <Footer />
	</div>
  );
};

export default ContactUS;
