import firebase from 'firebase/compat/app'; 
import 'firebase/compat/auth'; 
import 'firebase/compat/firestore'; 
import 'firebase/compat/functions';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
// import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const firebaseConfig = {
  apiKey: "AIzaSyDYVjTTAIaCy3ZlDybtpF8WQYqsUvMM8pE",
  authDomain: "word-galaxy-8f9c5.firebaseapp.com",
  projectId: "word-galaxy-8f9c5",
  storageBucket: "word-galaxy-8f9c5.appspot.com",
  messagingSenderId: "788091593182",
  appId: "1:788091593182:web:4e9b7f1fa54119fd967d4f",
  measurementId: "G-CX3G8TFLZC"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);

// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.
// const appCheck = initializeAppCheck(firebaseApp, {
//   provider: new ReCaptchaEnterpriseProvider('6LcnBtcmAAAAABqhyM8zJxRtFWFAQBjDXGRnFr4Q'),

//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   isTokenAutoRefreshEnabled: true
// });

const auth = firebase.auth();
let firestore;

// TODO: Read from env
const environment = "prod"

if (environment === "prod") {
	firestore = firebase.firestore();
}
else {
	// For local emulator
	// https://firebase.google.com/docs/emulator-suite/connect_firestore?authuser=0&hl=en
	firestore = getFirestore();
	connectFirestoreEmulator(firestore, 'localhost', 8080);
}

const functions = firebase.functions();

export { auth, firestore, firebaseConfig, functions };
export default firebaseApp;