import React from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { WordgalaxyWhite } from "../../assets";

/* 
Modal prompting user to verify their email 
Verification is needed so the user can reset their password,
and also if they use the Stripe portal, stripe prob needs verification
*/
const VerifyEmailModal = ({ emailAddress, verified }) => {
	
	const [open, setOpen] = React.useState(!verified)

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseAccept = () => {
		setOpen(false);
	};

	return (
		<div>
			<Dialog open={open} onClose={handleClose} fullScreen>
				<DialogContent className="flex-col items-center justify-center bg-blacklight text-white" sx={{width: "100vw"}}>
					<div className="text-3xl mb-3">Welcome to WordGalaxyAI</div>
					<div className="flex-col justify-center items-center text-center" style={{maxWidth: "80vw"}}>
						{/*<DialogContentText>*/}
						<div className="mt-6" style={{color: "#bcbcbc"}}>
							<p>Thank you for signing up!</p>
							<p>To get started, we've sent a verification email to: </p>
							<p className="mt-6" style={{textAlign: "center", color: "white"}}>{emailAddress}</p>
							<div className="flex-row mt-6 justify-center" >
								Please verify your email before continuing
									<div>
										<span className="ellipsis" style={{animationDelay: "0s", color: "white"}}>.</span>
										<span className="ellipsis" style={{animationDelay: "0.1s", color: "white"}}>.</span>
										<span className="ellipsis" style={{animationDelay: "0.2s", color: "white"}}>.</span>
									</div>
							  </div>
							 <p className="text-center px-10 mt-6 mx-4">Please take a moment to check your inbox and spam folder. 
							 Sometimes, email filters can be a bit overzealous and might mistakenly classify our emails as spam. 
							 If you can't find the verification email in your inbox, please check your spam folder as well.
							 </p>
							 <p className="text-center px-10 mt-6 mx-4">
							 Once you locate the email, open it and click on the verification link within. This step ensures the security of your account.
							 If you continue to experience any issues or have questions, please contact us at support@wordgalaxy.ai, or try signing up using a different address. We'll be happy to assist you.
							 Thank you for choosing WordGalaxyAI, and we look forward to embarking on a word-filled journey together!
							 </p>
							{/*Please check your email and verify your address before continuing.*/}
						{/*</DialogContentText>*/}
						</div>
						<div className="flex-row justify-center items-center">
							<img
	                src={WordgalaxyWhite}
	                className="mt-4 h-9 sm:h-14 rounded"
	                alt="Logo"
	              />
            </div>
					</div>
					{/*<DialogActions>
						<Button onClick={handleClose} disabled={!verified}>Cancel</Button>
						<Button onClick={handleCloseAccept} disabled={!verified}>Ok</Button>
					</DialogActions>*/}
				</DialogContent>
				
			</Dialog>
		</div>
	);
};

export default VerifyEmailModal;
