import { Button, Navbar } from "flowbite-react";
import React from "react";
import { Link } from "react-router-dom";
import { WordgalaxyBlack, WordgalaxyWhite } from "../../assets";
import { useEffect } from "react";
import { getAuth,  signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

const Header = ({ navPath, resultRef }) => {
	const onClick = (e) => {
		const href = window.location.href.substring(
			window.location.href.lastIndexOf('#') + 1
		);
		if(navPath==="/" || href==='pricing'){
			e.preventDefault();
			const element = document.getElementById("pricing");
			element.scrollIntoView({ behavior: "smooth" });
		}
	};

	const toFeature = (e) => {
		const href = window.location.href.substring(
			window.location.href.lastIndexOf('#') + 1
		);
		if(navPath==="/" || href==='features'){
			e.preventDefault();
			const element = document.getElementById("features");
			element.scrollIntoView({ behavior: "smooth" });
		}
	 
	}

	useEffect(() => {
		
		const href = window.location.href.substring(
			window.location.href.lastIndexOf('#') + 1
		); 
		
		let element;

		if (href) {
			 if(href==='features'){
					 element = document.getElementById("features");
			 }

			 if(href==='pricing'){
					element = document.getElementById("pricing");
			 }
				
			 if (element) {
					element.scrollIntoView({ behavior: 'smooth' });
			 }
		}
	}, []);

	const sessionId = localStorage.getItem("token");
	const username = localStorage.getItem("username");
	const auth = getAuth()
	const [ user ] = useAuthState(auth);

	const handleLogout = async (e) => {
		e.preventDefault();
		signOut(auth).then((res)=>{
			//console.log('Signed out.', res);
		}).catch((err)=>{
			console.warn('signOut error', err);
		})
	};

	const logo = <div><img
		src={WordgalaxyWhite}
		className="mr-3 h-9 sm:h-12 main_logo hover:cursor-pointer"
		alt="Logo"
	/></div>

	return (
		<Navbar
			id="header-nav"
			fluid={true}
			rounded={true}
			style={{
				backgroundColor: "transparent",
			}}
			className={`${
				navPath === "/about-us" ? "" : "text-white"
			} rounded-none py-5 border-none`}
		>
			{window.location.pathname === "/" ? logo : 
				<a href="/">{logo}</a>
			}
			<Navbar.Toggle />
			<div className="dsktop_menu"></div>
			<Navbar.Collapse>
				<ul className="block md:flex items-center">
					<Navbar.Link href="/#features"  className="ver_link_features border-none mx-3" onClick={toFeature}>
						<span className={`${navPath === "/about-us" ? "" : "text-white"} `}>
							Features
						</span>
					</Navbar.Link>
					<Navbar.Link href="/#pricing" className="ver_link_pricing mx-3 hover:cursor-pointer" onClick={onClick}>
						<span className={`${navPath === "/about-us" ? "" : "text-white"} `}>
							Pricing
						</span>
					</Navbar.Link>
					<Navbar.Link href="/about-us" className="ver_link_about_us border-none mx-3">
						<span className={`${navPath === "/about-us" ? "" : "text-white"} `}>
							About Us
						</span>
					</Navbar.Link>
					<Navbar.Link href="https://wordgalaxy.tolt.io/" className="ver_link_affiliates border-none mx-3" target="_blank">
						<span>Affiliates</span>
					</Navbar.Link>
					<Navbar.Link href="/contact-us" className="ver_link_contact_us border-none mx-3">
						<span className={`${navPath === "/contact-us" ? "" : "text-white"} `}>
							Contact Us
						</span>
					</Navbar.Link>
					{user !== null ? (
					<Navbar.Link href="/dashboard" className="border-none mx-3">
						<Button className="capitalize text-2xl mx-0 lg:mx-auto">
							Dashboard
						</Button>
					</Navbar.Link>
					):(
						<Navbar.Link href="/sign-up" className="border-none mx-3">
						<Button className="capitalize text-2xl mx-0 lg:mx-auto bg-lightBluebtn">
							Sign Up
						</Button>
					</Navbar.Link>
					)
				}
					{user !== null ? (
						<Navbar.Link className="border-none mx-3 text-white hover:cursor-pointer" onClick={(e) => handleLogout(e)}>
							<span>
								Logout
							</span>
						</Navbar.Link> 
					) : (
						<Navbar.Link href="/login" className="border-none mx-3">
							<span className="text-white">
								Login
							</span>
						</Navbar.Link>
					)}
					
				</ul>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default Header;
