import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DashboardNav } from "../../Components";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import SubscriptionForm from './../Products/SubscriptionForm';
import Notify from "simple-notify";
import { firestore } from './../../firebaseService';
import "simple-notify/dist/simple-notify.min.css";
import ContactForm from '../../Components/Support/contactForm'
import { collection, doc, getDoc, getDocs, addDoc, query, orderBy, startAt, limit, where } from "firebase/firestore";

const timestampToDate = (timestampStr) => {
	const timestamp = parseInt(timestampStr, 10)
	const date = new Date(timestamp);
	const humanReadableDate = date.toLocaleString();
	return humanReadableDate
}

const Support = () => {

	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate()
	const auth = getAuth()
	const [user, loading, error] = useAuthState(auth);
	const [headerKey, setHeaderKey] = useState(0);
 
	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
	}, [user, loading])

	const sendFeedback = async (text, email) => {
		
		if (loading || !user || user.uid === undefined) {
			notifyError(`There was an error submitting. Please try emailing support@wordgalaxy.ai`)
		}
		else {
			try {
				// const docRef = await addDoc(collection(firestore, "feedback"), {
				//   uid: user.uid,
				//   email: email,
				//   createdAt: new Date().toLocaleString(),
				//   message: text.slice(0, 3000)
				// }); 

				// Send Mail
				await addDoc(collection(firestore, "mail"), {
					to: "support@wordgalaxy.ai",
					message: {
						subject: `Feedback [${user.email}]`,
						html: `${text.slice(0, 3000)}
						<br></br>
						<br></br>
						uid: ${user.uid}
						<br></br>
						email: ${user.email}
						`,
						},
					});
			}
			catch (error) {
				console.error("Err sending feedback", error)
				notifyError(`There was an error submitting. Please try emailing support@wordgalaxy.ai`)
			}
		}
	}

	function notifyError(message) {
		new Notify({
			status: "error",
			title: "Error",
			text: message,
			effect: "fade",
			speed: 500,
			showIcon: false,
			showCloseButton: true,
			autoclose: true,
			autotimeout: 8000,
			gap: 40,
			distance: 40,
			type: 1,
			position: "right top",
		});
	}
	
	return (
		<div>
			<DashboardNav subscriptions={[]} key={headerKey} user={user} loading={loading} auth={auth} />

			{loading ? "" : <div className="bg-blackdark text-white py-10">
				<div className="container mx-auto px-3 md:px-5">
					<div className="bg-blacklight">
						<h1 className="text-4xl font-medium p-5 bg-blacktitle rounded">Support</h1>
						<div className="grid grid-cols-12 gap-5 md:gap-10 py-5 px-4">
							<div className="col-span-18 md:col-span-8 lg:col-span-10 bg-blacklight p-4">
								<p>Please contact us with any feedback or technical issues.
								</p>
								<br></br>
								<p>We use your suggestions to improve the Article Workflows!
								</p>
								<br></br>

								<ContactForm sendFeedback={sendFeedback} emailAddress={user?.email ?? ""} />

							</div>
						</div>
					</div>
				
					<div className="dash_subscription_sec">
					</div> 
				</div>
			</div>}
		</div>
	);
};

export default Support;
