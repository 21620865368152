import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Languages } from '../../languages.js'

export default function LanguageAutocomplete({ languageInitial, onSelectLanguage }) {

  const lang = languageInitial

  return (
      <Autocomplete
        id="lang-autocomplete"
        // disableClearable
        freeSolo
        sx={{width: "100%"}}
        label="Language"
        defaultValue={lang === "" ? "English" : lang}
        options={Languages.map((option) => option.name)}
        renderInput={(params) => <TextField {...params} label="Language"/>}
        onChange={(event, value) => onSelectLanguage(value)}
      />
    
  );
}
