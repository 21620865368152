import React, { useEffect, useState } from "react";
import { Button, Label, TextInput } from "flowbite-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
// import Notify from "simple-notify";
// import "simple-notify/dist/simple-notify.min.css";
import HashLoader from "react-spinners/HashLoader";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { sendEmailVerification } from 'firebase/auth';
import LoginImage from '../../Components/LoginImage'
import PrivacyPolicy from '../../Components/PrivacyPolicy'
import TermsOfService from '../../Components/TermsOfService'

const Login = () => {

	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const navigate = useNavigate();

	const handleLogin = async (e) => {
		e.preventDefault();
		setLoading(true);

		try {
			const auth = getAuth();
			const userCredential = await signInWithEmailAndPassword(auth, email, password);
			const user = userCredential.user

			let nextLocation = "/dashboard"

			// Re-send verification email if user signing in and not verified
			if (!user.emailVerified) {
				console.log("Sending verification email")
				sendEmailVerification(user) // auth.currentUser
				nextLocation = "/settings"
			}
			
			// Delay since instant login felt unnatural
			setTimeout(function() {
				navigate(nextLocation);
			}, 1000)
	
		} 
		catch (error) {
			setError("You entered an invalid email or password.");
			console.log(error);
		}

		setLoading(false);
	};

	return (
		<div className="grid grid-cols-12 h-full lg:h-screen bg-bluebutton">
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<LoginImage />
			</div>
			<div className="col-span-12 lg:col-span-6 py-10 flex justify-center items-center">
				<div className="container mx-auto bg-white rounded">
					<h3 className="text-4xl text-center py-2 mt-8">
						{loading ? "Signing in..." : "Welcome!"}
					</h3>
					<div className="login_form mx-auto p-6">
						
						{error && (
							<p className="text-red-500 text-center">{error}</p>
						)}
						<form onSubmit={handleLogin} className="mt-6">
							<Label htmlFor="email" className="mb-2">
								Email
							</Label>
							<TextInput
								type="email"
								id="email"
								value={email}
								placeholder="Email"
								style={{
									border: "none",
									// backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
								onChange={(e) => setEmail(e.target.value)}
								required
								className="mb-6 font-normal"
							/>

							<Label htmlFor="password" className="mb-2">
								Password
							</Label>
							<div className="relative">
								<TextInput
									type={showPassword ? "text" : "password"}
									id="password"
									value={password}
									placeholder="Password"
								style={{
									border: "none",
									// backgroundColor: "transparent",
									borderBottom: "1px solid lightGray",
									borderRadius: "0px",
								}}
									onChange={(e) => setPassword(e.target.value)}
									required
									className="mb-6"
								/>
								<FontAwesomeIcon
									icon={showPassword ? faEyeSlash : faEye}
									className="absolute top-2 right-2 cursor-pointer"
									onClick={() => setShowPassword(!showPassword)}
								/>
							</div>

							<Button
								type="submit"
								className="w-full mb-6 bg-lightBluebtn my-btn"
								disabled={loading}
							>
								{loading ? (
									<HashLoader color="#ffffff" loading={loading} size={20} />
								) : (
									"Log In"
								)}
							</Button>
						</form>
						
						<p className="text-center">
							Don't have an account?{" "}
							<Link to="/sign-up" className="color-link-dark display-flex">
								Sign up
							</Link>
						</p>
						<p className="text-center">
							<Link to="/forgot-password" className="color-link-dark">
							Forgot Password
							</Link>
						</p>
						<p className="text-sm py-1 text-center text-lightgray">
						By creating an Account, you agree to our
						<PrivacyPolicy />
						and
						<TermsOfService />
					</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
