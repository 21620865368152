import PrivacyPolicy from '../../Components/PrivacyPolicy'
import TermsOfService from '../../Components/TermsOfService'

const DashboardFooter = () => {
	return (
		<div className="flex-row justify-center items-center py-8 text-sm">
			<PrivacyPolicy className="px-4 text-white"/>
			<TermsOfService className="px-4 text-white"/>
		</div>
	);
}

export default DashboardFooter