import React, { useEffect, useState } from "react";
import { Box, InputLabel, Typography, List, ListItem, ListItemButton, ListItemText, Select, Stack, MenuList, MenuItem, TextField, FormControl, Button, Slider, TextareaAutosize, Link, Fade, Collapse, IconButton, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useNavigate, useSearchParams } from "react-router-dom";
import KeyModal from './keyModal.js'
import FaqModal from './faqModal.js'
import KeywordLinkModal from './keyword-link-modal.js'
import ArticleMode from './articleMode.js'
import FeedbackModal from './feedbackModal.js'
import { idToArticleStyle } from './defs-articles.js'
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState } from 'draft-js'; // convertToRaw
import { stateToHTML } from 'draft-js-export-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { replaceOccurrencesHandleHtml } from '../../js/utils/string-utils';
import { parseKeywordMultiStr } from './input-validation.js'
//import { sampleArticle } from './sample-article.js'
import { sampleKeywords } from './sample-keywords.js'
import { SnackbarProvider, enqueueSnackbar } from 'notistack';
import { StatusCodes, ArticleModes } from './defs.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown, faWindowMinimize, faSquareCaretDown, faSquareMinus } from "@fortawesome/free-solid-svg-icons";
import { doc, onSnapshot } from 'firebase/firestore'
import { firestore } from '../../firebaseService'
import LanguageAutocomplete from './language-autocomplete.js'

const modeQuick = ArticleModes.quick.id
const modeCustom = ArticleModes.custom.id

//const baseUrl = "http://127.0.0.1:5001/word-galaxy-8f9c5/us-central1/"
//const createUrl = baseUrl + "createArticle"
const createUrl = "https://createarticle-la5j7prpkq-uc.a.run.app"

const errorMessageGeneric = "There was an error generating. Please refresh the page and try again, or contact support."
const errorMessageContinuing = "Something went wrong. Your article may be found on the Documents page once it completes."

const statusTextCreating = "Creating article"
const statusTextCompleted = ""

async function sendRequestAPI(url = "", jwt = "", data = {}) {
	
	if (jwt === "") throw new Error("Request requires JWT for auth")

	const response = await fetch(url, {
		method: "POST",
		headers: {
		  "Content-Type": "application/json",
		  // 'Content-Type': 'application/x-www-form-urlencoded',
		  // "Authorization": `Bearer ${jwt}` // JWT from client
		  "Authorization": `${jwt}` // JWT from client
		},
		body: JSON.stringify(data), // body data type must match "Content-Type" header
	});

  return response
}

const sendFeedback = (message, emailOpt) => {
	let msg = { message: message }
	if (emailOpt !== "") msg.email = emailOpt
}


const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
	color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
	backgroundColor: theme.palette.common.black,
  },
}));

/* 
Interface for Quick + Custom Article Workflow
*/
export function GeneratorSimple({ loading, user, mode, setOpenQuotaError }) {

	const navigate = useNavigate()

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
	}, [loading, user])

	const getToken = async () => {

		let token;
		
		try {
			token = await user.getIdToken(true) ///* forceRefresh */ true)
			//console.log("Got token: ", token)
		}
		catch (error) {
			console.error("Error getting token: ", error)
			enqueueSnackbar("Error getting token " + error.message, { autoHideDuration: 5000, variant: 'default' })
		}
		
		return token;
	}

	const [isExpanded, setIsExpanded] = useState(true);

	const handleToggle = () => {
		setIsExpanded((prevExpanded) => !prevExpanded);
	};

	const articleAnalysisEmpty = {
		keywordPhrases: []
	}

	const [keyword, setKeyword] = useState("");
	const [keywordHasError, setKeywordHasError] = useState(false)
	const [keywordMultiInput, setKeywordMultiInput] = useState("");
	const [headerInput, setHeaderInput] = useState("");
	const [articleStyle, setArticleStyle] = useState(0);
	const [articleLengthSlider, setArticleLengthSlider] = useState(3);
	const [selectedLanguage, setSelectedLanguage] = useState(localStorage?.languageSetting ?? "English")
	const [articleProgress, setArticleProgress] = useState(-1.0)
	const [articleAnalysis, setArticleAnalysis] = useState(articleAnalysisEmpty)

	// For attaching hyperlinks to keyword phrases (KeywordLinkModal)
	const [linkModalOpen, setLinkModalOpen] = useState(false)
	const [linkModalPhrase, setLinkModalPhrase] = useState("")

	// Now this is being used for error, and editor div is used for an actual article result
	const [articleResult, setArticleResult] = useState("")

	const [createButtonEnabled, setCreateButtonEnabled] = useState(true) //useState(!loading)
	const [statusText, setStatusText] = useState("")

	const [editorState, setEditorState] = useState(EditorState.createEmpty());

	// Open the article parameters on mode change if no article active
	useEffect(() => {
		if (!isExpanded && createButtonEnabled) {
			const p = parseInt(articleProgress)
			if (p < 0 || p >= 100) setIsExpanded(true);
		}
  	}, [mode]);

	function articleProgressText() {
		return articleProgress >= 0 ? parseInt(articleProgress).toString() + "%" : ""
	}

	/* value: EditorState */
	const onEditorStateChange = (value) => {
		setEditorState(value);
	};

	const handleStyleChange = (event: SelectChangeEvent) => {
		setArticleStyle(event.target.value);
	};

	const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const kw = event.target.value;
		// Validate
		if (keywordHasError) setKeywordHasError(false)
		setKeyword(kw)
		if (kw === "" && articleProgress !== -1) setArticleProgress(-1)
	}

	const handleHeadersChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const text = event.target.value;
		setHeaderInput(text)
	}

	const handleKeywordMultiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const text = event.target.value;
		setKeywordMultiInput(text)
	}

	// When done listening, unsubscribe else resources used
	let unsubscribeCallback;

	const unsubscribeJob = () => {
		if (unsubscribeCallback) {
			unsubscribeCallback()
			unsubscribeCallback = undefined;
		}
	}

	// Check progress, status and update
	// If job ends, unsubscribe
	const handleJobUpdate = (job) => {

		if (job.status === StatusCodes.started || 
			job.status === StatusCodes.new
		) {

			if (job.progress >= 0 && job.progress <= 100) {
				// If job in database, display at least 1 percent
				setArticleProgress(Math.max(1, job.progress))
			}
			else console.warn("Progress missing from job: ", job)

		}
		else if (job.status === StatusCodes.finished) {
			
			setArticleProgress(100)

			try {
				let parsed = JSON.parse(job.json)
				handleArticleResult(parsed.text)
			}
			catch (error) {
				// Still try to give user back their text, in case of some kind of json parse error
				console.error("Err parsing resp text: ", job)
				handleArticleResult("There was an error formatting this text.\n\n" + job.json)
			}

			unsubscribeJob()
		}
		else if (job.status === StatusCodes.error) {

			setArticleProgress(100)

			let msg = job.errorShowUser ? job.errorShowUser : errorMessageGeneric

			// Update article display with the partial result if only part of article created
			if (job.hasOwnProperty('json') && job.json !== "" && job.json !== "{}") {
				let parsed = JSON.parse(job.json)
				if (parsed.hasOwnProperty('text') && parsed.text !== "") {
					handleArticleResult("Article result below is only partially completed due to error: " + msg + "\n\n" + parsed.text)
				}
			}

			console.log("Error generating: ", job)
			handleArticleResultError(msg)
			unsubscribeJob()
		}

	}

	const checkProgress = async (documentId) => {
 
		try {
			const job = doc(firestore, `articles/${documentId}`)
			//console.log("Job for proj check: ", job)

			unsubscribeCallback = onSnapshot(job, (snapshot) => {
				//console.log("Got snap: ", snapshot)
				if (snapshot.exists()) {
					const data = snapshot.data()
					//console.log("Snap update: ", data)
					handleJobUpdate(data)
				}
				else {
					// Once job completes, document is deleted, so listener ends up here if still running
					if (articleProgress === 0) {
						// Consider as an error if job has not started
						console.error(`No snapshot found for documentId [${documentId}]`, snapshot)
						handleArticleResultError(`There was an error looking up document: ${documentId}`)
					}
					else {
						// Generally this should not print as we unsubscribe first, but no real error if not
						console.log(`No snapshot found for documentId [${documentId}]`)
					}
				}
			})
		}
		catch (error) {
			
			console.error("Error (2) checking prog for doc id: ", documentId, error)
			handleArticleResultError(errorMessageContinuing + " id: " + documentId)
			unsubscribeJob()
		}
	}

	async function handleCreateArticle() {
		
		let kw = keyword.trim()
		let nHeaders = headerInput.split('\n').length

		if (kw === "") {
			setKeywordHasError(true)
			return
		}
		// else if (mode === modeCustom && articleLengthSlider == 1 && nHeaders > 20) {
		// 	enqueueSnackbar("Please use 20 or fewer headers", { autoHideDuration: 3000, variant: 'default' })
		// 	return
		// }
		// else if (mode === modeCustom && articleLengthSlider >= 2 && nHeaders > 15) {
		// 	enqueueSnackbar("Please use 15 or fewer headers", { autoHideDuration: 3000, variant: 'default' })
		// 	return
		// }
		else if (localStorage === undefined) {
			console.error("No local storage")
			return;
		} 
		else if (!localStorage.hasOwnProperty("apiKeyGPT")) {
			//console.error("No local storagekey set")
			enqueueSnackbar("Please set your API key first.", { autoHideDuration: 3000, variant: 'default' })
			return;
		}
		else if (localStorage.apiKeyGPT.trim() === "") {
			//console.error("API key cannot be empty")
			enqueueSnackbar("API key cannot be empty. Please set your API key.", { autoHideDuration: 3000, variant: 'default' })
			return;
		}

		let mock = false;
		let key = localStorage.apiKeyGPT;

		const modelVersions = ["gpt-3.5-turbo", "gpt-4"]
		let modelVersion = "gpt-3.5-turbo"
		
		if (localStorage.hasOwnProperty("modelVersion")) {
			if (modelVersions.includes(localStorage.modelVersion)) {
				modelVersion = localStorage.modelVersion
			}
			else console.error("Unrecognized model version: ", localStorage.modelVersion)
		}

		let request;
		
		// Clear keyword phrases column
		if (articleAnalysis.keywordPhrases.length > 0) setArticleAnalysis(articleAnalysisEmpty)
		// Shrink the parameter section
		setIsExpanded(false)
		setStatusText(statusTextCreating)
		setCreateButtonEnabled(false)
		setArticleProgress(-1)
		setEditorState(EditorState.createEmpty())

		// Get a refreshed auth token, otherwise previous might be expired
		let jwt = await getToken()
		if (!jwt) return;

		if (mock) {
			// For testing
			// keywordInput.value = "Keto Trail Mix"
			setKeyword("Keto Trail Mix")
			//keywordMultiInputElem.setInnerHTML = sampleKeywords
			console.log(keywordMultiInputElem)
			setKeywordMultiInput(sampleKeywords)

			class MockResponse {
				ok() { return true }
				async json() { 
					return new Promise((resolve, reject) => {
						resolve({ status: 'success', result: { articleId: 'i2bASv7MSTbWWG2Nd6A4' } } )
					})
				}
			}

			request = new Promise((resolve, reject) => {
				setTimeout(() => {
					resolve(new MockResponse());
				}, 1000)
			})

			// request = new Promise((resolve, reject) => {
			// 	setTimeout(() => {
			// 		resolve({status: 200, text: sampleArticle });
			// 	}, 1000)
			// })
		}
		else if (mode === modeQuick || mode === modeCustom) {

			let params = {
				apiKey: key,
				keyword: kw,
				articleStyle: articleStyle,
				articleLengthSlider: articleLengthSlider,
				headerInput: "",
				keywordMultiInput: "",
				modelVersion: modelVersion
			}

			if (selectedLanguage && selectedLanguage !== null && selectedLanguage !== "") {
				params.language = selectedLanguage.toLowerCase()
			}
			else params.language = "english"

			if (mode === modeCustom) {
				params.headerInput = headerInput
				params.keywordMultiInput = keywordMultiInput
			}

			request = sendRequestAPI(createUrl, jwt, params)
		}
		else {
			let err = `Unrecognized article mode: ${mode}`
			console.error(err)
			handleArticleResultError(err, {})
			return;
		}

		try {
			let respRaw = await request
			let res = await respRaw.json();
			console.log("Resp to create: ", res)

			/*
			{ status: 'success', result: { text: '' } }
			{ status: 'success', error: 'debug msg', errorShowUser: 'We're sorry!' }
			*/
				
			let msgToShow = errorMessageGeneric

			if (respRaw.ok && res.status === 'success') {

				if (!res.result.articleId) {
					console.error("Request success, but no article id", res)
					handleArticleResultError(msgToShow, res)
				}
				else {
					// Poll for progress / result, delay first call for a second
					setArticleProgress(0)

					// setTimeout(() => {
					// 	checkProgress(res.result.articleId, jwt)
					// }, 2500)

					setTimeout(() => {
						checkProgress(res.result.articleId)
					}, 4000)
				}
			}
			else {

				// If direct error from the API, display to user
				if (res.hasOwnProperty('errorShowUser')) {
					msgToShow = res.errorShowUser	
				}

				handleArticleResultError(
					msgToShow,
					res
				);
			}
		}
		catch (error) {
			console.error("Create resp: ", error)
			handleArticleResultError(errorMessageGeneric, {})
		}
	

	}

	const handleArticleResult = (result) => {
		
		setStatusText(statusTextCompleted)
		// enqueueSnackbar('Article success!', { autoHideDuration: 3000, variant: 'success'})

		if (articleProgress !== 100) setArticleProgress(100)

		if (result === undefined || result === "") {
			console.error("Empty result in handleArticleRes")
		}
		else updateEditorContent(result)

		// Update keywords for Link replacement
		try {
			let kws = parseKeywordMultiStr(keywordMultiInput)
			let phrasesAndCounts = kws.map((phrase) => {

				// Count case insensitive
				const count = (result.match(new RegExp(`${phrase}`, "gi")) || []).length;
				
				return { 
					phrase: phrase,
					count: count
				}
			})

			// Sort in descending order
			phrasesAndCounts.sort((a, b) => b.count - a.count);

			setArticleAnalysis({
				keywordPhrases: phrasesAndCounts
			})
		}
		catch (err) {
			console.warn("Error parsing kw multi input: ", keywordMultiInput, err)
			console.trace()
		}
		
		setCreateButtonEnabled(true);
	}

	const handleArticleResultError = (msgToUser, error) => {
		setArticleProgress(-1.0)
		setStatusText(statusTextCompleted)
		setIsExpanded(true)
		enqueueSnackbar(msgToUser, { autoHideDuration: 5800, variant: 'default' })
		setCreateButtonEnabled(true);
		unsubscribeJob()
		// "errorShowUser": "You exceeded your current quota, please check your plan and billing details. code: insufficient_quota Id: eioj3tEO5fcW9PvBxuAj",
		if (msgToUser.includes("You exceeded your current quota")) setOpenQuotaError(true)
	}

	const inputElemMaxWidth = "48vw"

	const rightColumnMaxWidth = "20vw"

	const keywordInput = <TextField 
		id="select-keyword" 
		label="Keyword" // Prompt
		variant="outlined" 
		style={{ width: inputElemMaxWidth, background: 'white' }}
		inputProps={{ maxLength: 100 }}
		onChange={handleKeywordChange}
	/>

	const keywordInputError = <TextField
		error
		id="outlined-error-helper-text"
		label="Error"
		defaultValue=""
		helperText="Enter a valid keyword phrase."
		onChange={handleKeywordChange}
	/>

	const headerInputElem = <div className="m2"><TextField
		id="select-headers"
		label="Headers"
		multiline
		rows={8}
		defaultValue=""
		placeholder="Headers (separate lines or by commas)"
		style={{ width: inputElemMaxWidth, backgroundColor: 'white' }}
		onChange={handleHeadersChange}
	/></div>

	/* Increase the frequency or presence of these terms */
	const keywordMultiInputElem = 	
		<div className="m2" style={{position: "relative"}}>
			<TextField
				id="select-headers"
				label="Keywords (Additional)"
				multiline
				rows={12}
				defaultValue=""
				style={{ width: rightColumnMaxWidth, backgroundColor: 'white' }}
				onChange={handleKeywordMultiChange}
			/>
			<BootstrapTooltip title="Increase the presence and frequency of these terms." placement="top" arrow>
				<div style={{position: "absolute", top: "5%", right: "5%", display: "flex", 
				justifyContent: "center", alignItems: "center", width: "0.4vw", height: "0.4vw",
				border: "1px solid gray", fontSize: "0.8rem", borderRadius: "8px", padding: "1vmin",
				cursor: "help"}}>
					<span>?</span>
				</div>
			</BootstrapTooltip>
		</div>
		

	// const articleResultTextArea = <TextareaAutosize
	// 	aria-label="Result"
	// 	placeholder=""
	// 	style={{ 
	// 		width: "88vw", 
	// 		visibility: articleResult === "" ? "hidden" : "visible",
	// 		borderRadius: "8px",
	// 		padding: "2vmin"
	// 	}}
	// 	value={articleResult}
	// />

	function apiKeyIfExists() {
		if (localStorage !== undefined) return localStorage.apiKeyGPT
		else return undefined
	}

	function setAndStoreLanguage(value) {
		if (localStorage !== undefined && value !== "" && value !== null) localStorage.languageSetting = value
		setSelectedLanguage(value)
	}
	
	const updateEditorContent = (textWithHtml) => {
		
		console.log("Updating editor w/ result", textWithHtml)
		
		if (textWithHtml !== undefined) {
			try {
				const contentBlock = htmlToDraft(textWithHtml);
				const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
				const doc = EditorState.createWithContent(contentState);
				//console.log("Editor state: ", doc)
				setEditorState(doc)
			}
			catch (error) {
				let errMsg = "Error parsing result html to editor state: " + error.message
				console.warn(errMsg)
				console.log(textWithHtml)
				enqueueSnackbar("Error parsing result html to editor state (result in console): " + error.message, { autoHideDuration: 7000, variant: 'error' })
			}
		}
	}

	// Test styling
	// useEffect(() => {
	// 	setTimeout(function() {
	// 		//updateEditorContent("<p>THIS IS A TEST.</p>\n<h1>Big text</h1><p>Ok here is a para.\nHere is the next sent.\nHere's the one after.</p><h2>here is title</h2>")
	// 		updateEditorContent('<p>THIS IS <span style="background-color: #ddd;">A TEST</span>.</p>\n<h1>Big text</h1><p>Another paragraph</p>')
	// 	}, 3000)	
	// }, [])
		
	// https://jpuri.github.io/react-draft-wysiwyg/#/docs
	const EditorComponent = <Editor
		// toolbarHidden
		editorStyle={{fontSize: 12}}
		editorState={editorState}
		// toolbarClassName="toolbarClassName"
		// wrapperClassName="wrapperClassName"
		// editorClassName="editorClassName"
		onEditorStateChange={onEditorStateChange}
		toolbar={{
			options: ['inline', 'blockType'],
			inline: {
				options: ['bold', 'italic', 'underline'],//, 'strikethrough', 'monospace'],
				bold: { className: 'bordered-option-classname' },
				italic: { className: 'bordered-option-classname' },
				underline: { className: 'bordered-option-classname' },
				strikethrough: { className: 'bordered-option-classname' },
				code: { className: 'bordered-option-classname' },
			},
			blockType: {
				className: 'bordered-option-classname',
			},
			// fontSize: {
			// 	className: 'bordered-option-classname',
			// 	options: [12]
			// },
			// fontFamily: {
			// 	className: 'bordered-option-classname',
			// },
		}}
		// Special fix because Tailwind CSS prevents default header styling from working
		// https://github.com/jpuri/react-draft-wysiwyg/issues/1240
		// Or if more issues, try looking at: 
		// import 'draft-js/dist/Draft.css';
		blockStyleFn={(contentBlock) => {
			const type = contentBlock.getType();
			if (type === 'header-one') {
				return 'text-4xl font-bold'; // Apply Tailwind CSS utility classes
			}
			else if (type === 'header-two') {
				return 'text-3xl font-bold'; 
			}
			else if (type === 'header-three') {
				return 'text-2xl font-bold'; 
			}
			else if (type === 'header-four') {
				return 'text-xl font-semibold'; 
			}
			else if (type === 'header-five') { // or lg
				return 'text-lg font-semibold'; 
			}
			else if (type === 'header-six') { // or lg
				return 'text-sm font-semibold'; 
			}
		}}
	/>;







	// TODO: Extract

	// For keywords list / replacement
	const [selectedIndex, setSelectedIndex] = useState(-1);

	/* 
	When user clicks a keyword phrase, 
	highlight occurrences of the phrase in the document
	and pop up a modal so they can paste hyperlinks to replace
	*/
	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement, MouseEvent>,
		index: number,
	) => {
		setSelectedIndex(index);


		// TODO: Update counts as article content changes from edits
		// Possibly some case involving overlapping keywords


		let phraseAndCount = articleAnalysis.keywordPhrases[index]

		if (linkModalOpen) {
			setLinkModalOpen(false)
			setLinkModalPhrase("")
		}
		else {
			// Only open if phrase exists to do replacement
			if (phraseAndCount.count > 0) {
				setLinkModalPhrase(phraseAndCount.phrase)
				setLinkModalOpen(true)
			}
		}
	};

	/* linkText: the value of the input field, newline separated URLs */
	const addLinksToPhrase = (phrase, linkText) => {

		let urls = linkText.split("\n").map((link) => link.trim()).filter((link) => link !== "")
		
		if (urls.length === 0) return 0;
		else {

			// Find keyword phrase occurrences in the text

			//const editorState = editorRef.current.getEditorState();
			//const selectedText = editorState.getSelection().toString();

			// Text with no HTML
			// .blocks, List of blocks, each has .text
			//let content = convertToRaw(editorState.getCurrentContent())
			//let text = content.blocks.map((block) => block.text).join('\n')
			//console.log("Current text: ", text)
			const textWithHtml = stateToHTML(editorState.getCurrentContent());


			// Do replacement but protect titles, html, except p tags
			let replacements = urls.map((url) => { return {
				text: "",
				textBefore: `<a href="${url}" target="_blank" rel="noopener">`,
				textAfter: '</a>'
			}})

			let caseInsensitive = true
			let targetIsWordOrPhrase = true;
			let debug = false
			let result;

			// Try skip meta title, meta description, main title, to prevent doing link replacements
			let metadataAndTitle = ""; 
			let articleBody = textWithHtml;
			//let articleStartIdx = textWithHtml.indexOf("<p>")
			let articleStartIdx = textWithHtml.indexOf("</h1>")

			if (articleStartIdx !== -1) {
				metadataAndTitle = textWithHtml.slice(0, articleStartIdx)
				articleBody = textWithHtml.slice(articleStartIdx + 5) // +5 length of h1 closing
			}

			try {
				result = replaceOccurrencesHandleHtml(articleBody, phrase, replacements, caseInsensitive, targetIsWordOrPhrase, debug)
				if (result.numReplacements > 0) {
					let rejoined = metadataAndTitle + result.text
					if (debug) console.log("Result after URL repl: ", rejoined)
					updateEditorContent(rejoined)
					//enqueueSnackbar(`Added ${result.numReplacements} links`, { autoHideDuration: 3000, variant: 'info' })
				}

				enqueueSnackbar(`Added ${result.numReplacements} links`, { autoHideDuration: 3000, variant: 'info' })
			}
			catch (error) {
				console.error("Error with link replacement: ", error, result)
				console.trace()
				result = { numReplacements: 0 }
				enqueueSnackbar(`Failed to add links.`, { autoHideDuration: 1700, variant: 'error' })
			}

			return result.numReplacements
		}
	}

	// setTimeout(function() {
	// 	enqueueSnackbar(`Failed to add links.`, { autoHideDuration: 3200, style: { backgroundColor: "rgb(59, 161, 82)" }} )
	// }, 1000)

	const keywordList = articleAnalysis.keywordPhrases.length === 0 ? "" : 
		<div className="mt-5 p-3 mr-6" style={{width: "26vw", height: "fit-content", borderRadius: "8px", border: "1px solid gray", marginBottom: "2vw"}}>
		{/*cdcdcd*/}
			<div className="flex-row" style={{
				justifyContent: "center", alignItems: "center", fontWeight: "600", paddingBottom: "1vw", paddingTop: "1vw"
			}}>
				Keyword Phrases
			</div>
			<KeywordLinkModal open={linkModalOpen} setOpen={setLinkModalOpen} phrase={linkModalPhrase} addLinksToPhrase={addLinksToPhrase} />
			<div className="flex-col" style={{alignItems: "center"}}>
				<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
						<List dense={true} component="nav" aria-label="secondary">
							{articleAnalysis.keywordPhrases.map((phraseAndCount, i) => (
								<ListItem key={i} disablePadding>
									<ListItemButton
										sx={{
											'borderRadius': '8px',
											'color': phraseAndCount.count > 0 ? "" : "#9f9f9f",
											'&.Mui-selected': {
											  backgroundColor: 'rgb(224, 224, 224)'
											},
											"&.Mui-hover": {
												backgroundColor: 'rgb(224, 224, 224)'
											},
											"&.Mui-selected:hover": {
												backgroundColor: 'rgb(224, 224, 224)'	
											}
										}}
										// disabled={phraseAndCount.count <= 0}
										selected={selectedIndex === i}
										onClick={(event) => handleListItemClick(event, i)}
									>
										{/* Could chop phrase at 30 chars */}
										<ListItemText primary={phraseAndCount.phrase}/>
										<ListItemText primary={phraseAndCount.count} sx={{textAlign: "right"}}/>
									</ListItemButton>
								</ListItem>
							))}
						</List>
				</Box>
			</div>
		</div>
	

	const pageTitle = 
		<Typography variant="h4" component="div" fontWeight={500}>
		  {mode === modeQuick ? "Quick" : "Custom"} Article Workflow
		</Typography>
		{/*<h2>{mode === modeQuick ? "Quick" : "Custom"} Article Workflow</h2>*/}

	// would be nice to set ".rdw-editor-toolbar" padding-left to 0

	const dropdownClosedIcon = <FontAwesomeIcon
		icon={faSquareCaretDown}
		color={'#0071CE'}
		className="mr-3"
		size="lg"
	/>

	const dropdownOpenIcon = <FontAwesomeIcon
		icon={faSquareMinus}
		//color={`${active === item?.id ? "#0072f6" : "#0072f6"}`}
		color={'#0071CE'}
		className="mr-3"
		size="lg"
	/>

	return (
		<div className="gen-simple flex-col" style={{width: "83vw"}}>
			<div style={{width: "100%"}}>
			  
			  <div className="flex-row" style={{justifyContent: "space-between", alignItems: "center"}}>
				  <IconButton onClick={handleToggle}>
					<div className="ml-3">{isExpanded ? dropdownOpenIcon : dropdownClosedIcon }
					</div>
				  </IconButton>

				  <div className="flex-row ml-3 p-3" style={{width: "100%"}}>
					{statusText}
					{statusText === "" ? "" :
						<div>
							<span className="ellipsis" style={{animationDelay: "0s"}}>.</span>
							<span className="ellipsis" style={{animationDelay: "0.1s"}}>.</span>
							<span className="ellipsis" style={{animationDelay: "0.2s"}}>.</span>
						</div>
					}
				  </div>
				  
				  <div className="mr-4 mt-2">
					<Box sx={{ display: 'flex', alignItems: "center"}}>
						<Box className="m2">
							<Typography fontWeight={800} color={"#1b1b1b"}>
								{articleProgressText()}
							</Typography>
						</Box>
						<Fade
							in={!createButtonEnabled}
							style={{
							  transitionDelay: createButtonEnabled ? '0ms' : '500ms',
							}}
							unmountOnExit
						  >
						  <Box className="p-2 mt-2 mr-2">
							<CircularProgress thickness={9} />
							</Box>
						</Fade>
					</Box>
					
				  </div>
			  </div>

			  <Collapse in={isExpanded}>
				{/*<div style={{backgroundColor: "red"}}>*/}
				<div>
					<div className="flex-row" style={{width: "100%", justifyContent: "center"}}>
						<div className="flex-col">
							<div className="flex-row" style={{justifyContent: "center"}}>
								<div className="px-9 py-4">{pageTitle}
								</div>
								<div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
									<KeyModal keyInitial={apiKeyIfExists()} />
								</div>
							</div>

							<div className="gen-content flex-col m2">
								<div className="flex-row">
									<div className="flex-col">
										<div className="m2 text-color-white">
											{keywordHasError ? keywordInputError : keywordInput} 
										</div>
										{mode === modeCustom ? headerInputElem : "" }
										<div className="flex-row m2">
											<div className="pr-4">
												<FormControl className='bg-white'>
													<InputLabel id="select-style-label" className=''>Style</InputLabel>
														<Select
															labelId="select-style-label"
															id="select-style"
															value={articleStyle}
															label="Style"
															onChange={handleStyleChange}
														>
														<MenuItem value={0}>{idToArticleStyle[0]}</MenuItem>
														<MenuItem value={1}>{idToArticleStyle[1]}</MenuItem>
														<MenuItem value={2}>{idToArticleStyle[2]}</MenuItem>
													</Select>
												</FormControl>
											</div>
											<LanguageAutocomplete languageInitial={selectedLanguage} onSelectLanguage={setAndStoreLanguage} />
										</div>
									</div>
									<div className="flex-col">
										{mode === modeCustom ? keywordMultiInputElem : "" }
									</div>
								</div>
								<div className="m2" style={{width: "30vw"}}>
									<Typography id="article-length-slider-label" className='' gutterBottom>
										Article Length
									</Typography>
									<Slider 
										defaultValue={3} 
										aria-label="Paragraphs per section" 
										valueLabelDisplay="auto"
										step={1}
										min={1}
										max={5}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											setArticleLengthSlider(event.target.value);
										}}
									/>
								</div>
								<div className="flex-row m2">
									<Button 
										variant="contained" 
										disabled={!createButtonEnabled}
										onClick={handleCreateArticle}
									>Create Article
									</Button>
								</div>
							</div>
						</div>
					</div>

					{/*<div className="qaw-right flex-col m4">
						<Stack direction="row" spacing={2} sx={{width: "10vw", justifyContent: "flex-end"}}>
							<MenuList>
							 <ArticleMode 
								modeDisplayed={mode === modeQuick ? modeCustom : modeQuick} 
								toggleMode={toggleMode} 
							 />
							  <FaqModal />
							  <FeedbackModal sendFeedback={sendFeedback} />
							  <div style={{color: "#cccccc", width: "100%", textAlign: "right"}}>
								<Link href="/privacyPolicy.txt" 
									underline="hover" color="inherit" target="_blank" 
									style={{fontSize: "0.8rem", fontWeight: "bold", textAlign: "right"}}
									sx={{pr: 2}}>
									Privacy Policy
								</Link>
							</div>
							</MenuList>
						</Stack>						
					</div>*/}
				</div>
			  </Collapse>
			</div>

			<div id="editor-container" className="flex-row" style={{width: "100%", justifyContent: "center"}}>
				{/*, backgroundColor: "#e5e5e5"*/}
				<div className="px-10 mb-10 mt-2" style={{minHeight: "92vh", width: "100%"}}>{EditorComponent}</div>
				{mode === modeCustom ? keywordList : ""}
			</div>
			{/*59, 161, 82 green*/}
			{/*<SnackbarProvider style={{ backgroundColor: "rgb(27, 120, 204)" }}/>*/}
			<SnackbarProvider  
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				iconVariant={{
					success: '✅ ',
					error: '✖️ ',
					warning: '⚠️ ',
					info: 'ℹ️ ',
				}}/>
		</div>
	);
}
