import React, { useState } from "react";
import { Accordion } from "flowbite-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const FAQs = ({ FAQsData }) => {
  const [itemId, setItemId] = useState(1);

  const handleOpen = (id) => {
    if (itemId === id) {
      setItemId(-1)
    }
    else {
      const test = FAQsData?.find((item) => item.id == id);
      setItemId(test.id);
    }
  };

  return (
    <Accordion className="border-none" alwaysOpen={true} arrowIcon={""}>
      {FAQsData?.map((item) => (
        <Accordion.Panel
          className="border-none focus:bg-shadow focus:shadow-none focus:border-none"
          key={item.id}
        >
          <Accordion.Title
            onClick={() => handleOpen(item.id)}
            className="bg-transparent focus:bg-transparent focus:shadow-none focus:border-none faq-content"
          >
            <FontAwesomeIcon
              icon={itemId === item.id ? faMinus : faPlus}
              color="#6a67ff"
              className="px-2"
            />
            {item.question}
          </Accordion.Title>
          <Accordion.Content
            style={{
              display: itemId === item.id ? "block" : "none",
            }}
          >
            <p className="mb-2 text-gray-500 dark:text-gray-400 faq-content">
              {item.answer}
            </p>
          </Accordion.Content>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
};

export default FAQs;
