import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebookF,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { Button } from "flowbite-react"; 
import { Link, useNavigate } from "react-router-dom"; 
import { WordgalaxyWhite2 } from "../../assets";
import '../../css/common.css'

const Footer = ({ navPath, resultRef }) => {
  
  const navigate = useNavigate()

  const toPricing = (e) => {
    
    if (window.location.pathname === '/') {
      e.preventDefault();
      const element = document.getElementById("pricing");
      element.scrollIntoView({ behavior: "smooth" });
    }
    else {
      window.location.href = '/#pricing';
    }
  };
  
  const toFeature = (e) => {
    
    if (window.location.pathname === '/') {
      e.preventDefault();  
      const element = document.getElementById("features");
      element.scrollIntoView({ behavior: "smooth" });
    }
    else {
      window.location.href = '/#features';
    }
  }

  const scrollTopHomePage = () => {
     
    if (window.location.pathname === "/") {// || window.location.pathname.startsWith("/#")) {
      const element = document.getElementById("header-nav");
      element.scrollIntoView({ behavior: "smooth" });
      //window.scrollTo(0, 0);
    }
    else {
      navigate('/')
    }
  }

  return (
    <div className="bg-darkBlue appfooter  overflow-hidden">
      <div className="container mx-auto pt-5">
        <div className="flex-row justify-between items-center">
          <div className="">
            <img
              src={WordgalaxyWhite2}
              className="mr-3 h-9 sm:h-14 hover:cursor-pointer"
              alt="Logo"
              onClick={scrollTopHomePage}
            />
          </div>
          <div>
            <div className="flex-row justify-start items-center w-full">
              {/*<div className="text-white mx-3" style={{whiteSpace: "nowrap"}}><a href="/#features">Features</a></div>
              <div className="text-white mx-3" style={{whiteSpace: "nowrap"}}><a href="/#pricing">Pricing</a></div>
              <div className="text-white ml-3" style={{whiteSpace: "nowrap"}}><a href="/contact-us">Contact Us</a></div>*/}
              <div className="text-white mx-3" style={{whiteSpace: "nowrap"}}><a href="#" onClick={toFeature}>Features</a></div>
              <div className="text-white mx-3" style={{whiteSpace: "nowrap"}}><a href="#" onClick={toPricing}>Pricing</a></div>
              <div className="text-white ml-3" style={{whiteSpace: "nowrap"}}><a href="/contact-us">Contact Us</a></div>
            </div>
          </div>
        </div>
        <hr
          className="border-none mb-5 mt-2"
          style={{ height: "1px", backgroundColor: "#b8b8b8" }}
        />
        <div className="flex-row justify-center mb-5">
          <div className="flex items-center">
            <p className="text-sm" style={{color: "#C4D6ED"}}>
            2023 WORDGALAXY.AI ALL RIGHTS RESERVED
            </p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Footer;
