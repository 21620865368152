import { Button } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { DashboardNav } from "../../Components";
import SubscriptionForm from './../Products/SubscriptionForm';
import Notify from "simple-notify";
import { firestore } from './../../firebaseService';
import "simple-notify/dist/simple-notify.min.css";
import VerifyEmailModal from '../../Components/VerifyEmail'
import { collection, doc, getDoc, getDocs, query, orderBy, startAt, limit, where } from "firebase/firestore";
import {STRIPE_PUBLIC_KEY, STRIPE_PORTAL_URL} from './../../config/env';
import ModelVersionSelect from '../../Components/ModelVersionSelect'

const Subscription = ({ user, loading, auth }) => {

	const [subscriptions, setSubscriptions] = useState([]);
	const [ searchParams ] = useSearchParams();
	const navigate = useNavigate()
	const [checkingEnrollment, setCheckingEnrollment] = useState(true)
	const [teachableEnrolled, setTeachableEnrolled] = useState(false)
	const [headerKey, setHeaderKey] = useState(0);
 	
 	// For limiting how frequent user is refeshed
	const [intervalId, setIntervalId] = useState(null);

	useEffect(() => {
		if (loading) return;
		if (!user) return navigate("/");
		fetchTeachableStatus()
		fetchSubscription();
	}, [user, loading])

	useEffect(() => {
		if (searchParams.get('success')) {	
			new Notify({
				status: "success",
				title: "Congratulations",
				text: `${searchParams.get('name')} successfully subscribed.`,
				effect: "fade",
				speed: 300,
				showIcon: false,
				showCloseButton: true,
				autoclose: true,
				autotimeout: 10000,
				gap: 40,
				distance: 40,
				type: 1,
				position: "right top",
			});
		}
		if (searchParams.get('fail')) {
			new Notify({
			  status: "error",
			  title: "Error",
			  text: 'Payment has failed.',
			  effect: "fade",
			  speed: 300,
			  customClass: null,
			  customIcon: null,
			  showIcon: false,
			  showCloseButton: true,
			  autoclose: true,
			  autotimeout: 2000,
			  gap: 20,
			  distance: 20,
			  type: 1,
			  position: "right top",
			});
			}
	}, [])

	// If a user has not verified email, monitor their verification status
	
	useEffect(() => {
		if (user && !user.emailVerified && !intervalId) {
		  const id = setInterval(() => {
			user.reload().then(() => {
			  if (user.emailVerified) {
				console.log('Email verified');
				clearInterval(id); // Stop the interval
			  } 
			  else {
				console.log('Email is not verified');
			  }
			}).catch(error => {
			  console.log('Error refreshing user:', error);
			});
		  }, 5000);
		  setIntervalId(id);
		}

		// Clean up the interval when the component unmounts
		return () => {
		  if (intervalId) {
			clearInterval(intervalId);
		  }
		};
	  }, [user, intervalId]);

	
	// TODO: future, just check claims	
	const fetchSubscription = async () => {
		
		try {
			const snapshot = await firestore
				.collection("customers")
				.doc(user?.uid)
				.collection("subscriptions")
				.get();
			
			const subscribeData = await Promise.all(
				snapshot.docs.map(async (doc) => {
					const subscribe = doc.data();
					subscribe.id = doc.id;
					return subscribe;
				})
			);
			
			setSubscriptions(subscribeData);
			//setHeaderKey((headerKey) => headerKey + 1) // ???
			console.log({ subscribeData });
		} 
		catch (error) {
			console.error('Error fetching data:', error);
		}
	};

	// When user first signs up and enters, might have to check the db table instead of the user obj, unless you refresh it quickly
	const fetchTeachableStatus = async () => {
		const token = await user.getIdTokenResult() // Try pass true param if not refreshed
		//console.log("Token: ", token)

		if (token.claims?.teachable) {
			setTeachableEnrolled(true)
		}
		setCheckingEnrollment(false)
	}

	const noSubscriptionMessage = 
		<div className="col-span-18 md:col-span-8 lg:col-span-12 bg-blacklight p-4">
			<div className="text-1xl">
				<p className="mb-2">
					No subscription was found, please select a plan below.
				</p>
				<p className="mb-2">
					Once subscribed, you will be able to use Article Workflows to generate content.
				</p>
			</div>
		</div>

	const teachableMessage = 
		<div className="col-span-18 lg:col-span-12 px-4 pt-4 mt-5">
			Teachable Enrollment is active.
		</div>

	const settingsMessage = () => {
		if (loading || checkingEnrollment) return ""
		else if (teachableEnrolled) {
			return teachableMessage
		}
		else if (subscriptions.length === 0) return noSubscriptionMessage
		else return ""
	}

	const showModal = () => { return !loading && user && user.email && !user.emailVerified }
	//const showModal = !loading && user && user.email;

	const subscriptionSection = () => {
		if (loading || checkingEnrollment) return ""//Loading..."
		else if (teachableEnrolled && subscriptions.length === 0) return ""
		else return (
			<div className="dash_subscription_sec mb-6">
				<SubscriptionForm />
			</div>
		);
	}

	// If user cancelled, show time remaining
	const cancelledMessage = () => {
		if (loading || checkingEnrollment) return ""
		else {

			if (subscriptions) {

				// Detect cancelled, but still active plan
				try {

					let plansActiveButCancelled = [];

					for (let i=0; i < subscriptions.length; i++) {

						let s = subscriptions[i]

						//.items exists
						//i.price.active
						//i.price.product.id === "prod_O2h4zdebnL3MkS" // product.name === "Individual Plan"
						
						// .plan.product === "prod_O2h4zdebnL3MkS"
						// .plan.active

						if (s.status === "active" && s.cancel_at_period_end) {
							
							const endDate = s.cancel_at.seconds * 1000
							
							// Sanity check
							if (endDate > Date.now()) {

								const d = new Date(endDate)
								const endStr = d.toLocaleString().split(",")[0]
								
								plansActiveButCancelled.push({
									date: endStr	
								})
							}		
						}
					}

					if (plansActiveButCancelled.length === 0) return ""
					else if (plansActiveButCancelled.length === 1) {
						return (
							<div className="col-span-18 lg:col-span-12 px-4 pt-4 mt-2">
								Your paid subscription will become inactive on: {plansActiveButCancelled[0].date}
							</div>
						)
					}
					else return (
						<div className="col-span-18 lg:col-span-12 px-4 pt-4 mt-2">
							<p>
								Your paid subscriptions will become inactive on: {plansActiveButCancelled.map((p) => p.date).join(", ")}. 
							</p>
							<p className="mt-4">
								Use the Stripe portal button below for more information.
							</p>
						</div>
					)
				}
				catch (err) {
					console.log("Error checking cancelled plans: ", err)
				}
			}

			return ""
		}
	}

	const getPortalLink = () => {
		if (loading || !user || !user.email) return STRIPE_PORTAL_URL
		else return STRIPE_PORTAL_URL + "?prefilled_email=" + user.email
	}

	return (
		<div>
			<DashboardNav subscriptions={subscriptions} key={headerKey} user={user} loading={loading} auth={auth} />

			{!showModal() ? "" : 
				<div className="flex justify-center items-center">
					<VerifyEmailModal emailAddress={user.email} verified={user.emailVerified} />
				</div>
			}

			{loading ? "" : 
				<div className="bg-blackdark text-white py-10 flex-col justify-center items-center">
					
				{/*<div className="container mx-auto px-3 md:px-5 bg-blackmedium">*/}
				<div className="container mx-auto px-3 md:px-5">
					<div className="bg-blacklight">
						<h1 className="text-4xl font-medium p-5 bg-blacktitle rounded">Settings</h1>
						<div className="grid grid-cols-12 gap-5 md:gap-10 py-5 px-6">
							{settingsMessage()}
							{/*{cancelledMessage()}*/}
							{subscriptions.length === 0 ? "" :
								<div className="col-span-18 md:col-span-8 lg:col-span-12 bg-blacklight p-4">
									<div className="flex-row mb-2 justify-between items-center">
										<div>
											<p>To manage your subscription and billing details, access Stripe here.</p>
										</div>
										<Link to={getPortalLink()} target="_blank">
											<Button className="capitalize bg-lightBluebtn text-white my-3 lg:my-0 px-1 py-1 ml-5 rounded">
												<span className="text-m">
													Manage Subscription
												</span>
											</Button>
										</Link>
									</div>
								</div>
							}
							<div className="col-span-18 lg:col-span-12 px-4 pt-4 mt-2" style={{borderTop: "1px solid #313131"}}>
								<div className="flex-row items-center justify-between">
									<div className="mt-4 pr-7" style={{ width: "60%", textAlign: "right" }}>Select a GPT model version: </div>
									<div className="flex justify-center" style={{width: "40%"}}>
										<ModelVersionSelect />
									</div>
								</div>
							</div>
						</div>
					</div>
					
					{subscriptionSection()}
					{/*<h2 className="text-xl font-medium py-4 mt-8 px-4">Your Plan</h2>
					<div className="flex justify-between items-center flex-wrap lg:flex-nowrap p-3 bg-blacklight rounded px-4">
						<p className="capitalize">
							<span className="font-medium">Free: </span> 1,322 / 2, 500 words
							used
						</p>
						<Button className="capitalize bg-lightBluebtn text-white my-3 lg:my-0 px-2 py-1 rounded">
							Settings
						</Button>
					</div>*/}
				</div>
			</div>}
		</div>
	);
};

export default Subscription;
