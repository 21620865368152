import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WordgalaxyWhite } from "../../assets";
// import { WordgalaxyBlueDarkTransparent } from "../../assets";
import { Link } from "react-router-dom";

const Sidebar = ({ active, handleActive, SidebarData, SidebarDataBottom }) => {
  return (
    <div className="bg-blackmedium h-screen w-full">
      <div className="h-screen">
        <Link to="/dashboard">
          <div className="w-full flex justify-center items-center my-2 ">
            <img
              src={WordgalaxyWhite}
              className="mr-3 h-9 sm:h-14 inline-block"
              alt="Logo"
            />
          </div>
        </Link>
        <div className="flex-col h-full" style={{alignItems: "space-between"}}>
          <ul>
            {SidebarData?.map((item) => (
              <li
                className={`${
                  active === item?.id ? "bg-graytitle" : ""
                } p-3 ml-5 my-10 rounded-l-2xl hover:cursor-pointer text-white`}
                onClick={() => handleActive(item.id)}
                key={item.id}
              >
                <FontAwesomeIcon
                  icon={item.icon}
                  color={`${active === item?.id ? "#0072f6" : "#0072f6"}`}
                  className="mr-3"
                  size="lg"
                />
                {item.text}
              </li>
            ))}
          </ul>
          <div className="flex-col h-full pb-14 mb-3 text-white" style={{justifyContent: "flex-end"}}>
            <ul>
            {SidebarDataBottom?.map((item) => (
              <li
                className={`p-3 ml-5 my-2 rounded-l-2xl hover:cursor-pointer text-white`}
                onClick={() => handleActive(item.id)}
                key={item.id}
              >
                {/*<FontAwesomeIcon
                  icon={item.icon}
                  color={`${active === item?.id ? "#0072f6" : "#0072f6"}`}
                  className="mr-3"
                  size="lg"
                />*/}
                {item.text}
              </li>
            ))}
          </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
