import { WordgalaxyWhite2 } from "../../assets";

const LoginImage = () => {
	return (
		<div className="container mx-auto text-center">
			<img
				src={WordgalaxyWhite2}
				className="mr-3 h-9 sm:h-20 inline-block w-full"
				style={{height: "auto"}}
				alt="Logo"
			/>
			<h2 className="text-white text-3xl uppercase pt-2 lg:pt-4">
				The #1 content creation tool in the industry.
			</h2>
			<p className="text-white text-sm py-5">
				Generate SEO-optimized long-form content that ranks on Google!
			</p>
		</div>
	);
}

export default LoginImage