export const Languages = [
  { name: "Albanian" },
  { name: "Arabic" },
  { name: "Armenian" },
  { name: "Azerbaijani" },
  { name: "Basque" },
  { name: "Belarusian" },
  { name: "Bengali" },
  { name: "Bulgarian" },
  { name: "Catalan" },
  { name: "Chinese" },
  { name: "Croatian" },
  { name: "Czech" },
  { name: "Danish" },
  { name: "Dutch" },
  { name: "English" },
  { name: "Estonian" },
  { name: "Filipino" },
  { name: "Finnish" },
  { name: "French" },
  { name: "Galician" },
  { name: "Georgian" },
  { name: "German" },
  { name: "Greek" },
  { name: "Gujarati" },
  { name: "Hebrew" },
  { name: "Hindi" },
  { name: "Hungarian" },
  { name: "Icelandic" },
  { name: "Indonesian" },
  { name: "Italian" },
  { name: "Japanese" },
  { name: "Kannada" },
  { name: "Kazakh" },
  { name: "Korean" },
  { name: "Latvian" },
  { name: "Lithuanian" },
  { name: "Macedonian" },
  { name: "Malay" },
  { name: "Malayalam" },
  { name: "Marathi" },
  { name: "Mongolian" },
  { name: "Norwegian" },
  { name: "Persian" },
  { name: "Polish" },
  { name: "Portuguese" },
  { name: "Punjabi" },
  { name: "Romanian" },
  { name: "Russian" },
  { name: "Serbian" },
  { name: "Slovak" },
  { name: "Slovenian" },
  { name: "Spanish" },
  { name: "Swahili" },
  { name: "Swedish" },
  { name: "Tamil" },
  { name: "Telugu" },
  { name: "Thai" },
  { name: "Turkish" },
  { name: "Ukrainian" },
  { name: "Urdu" },
  { name: "Uzbek" },
  { name: "Vietnamese" },
  { name: "Welsh" },
  { name: "Xhosa" },
  { name: "Yiddish" },
  { name: "Zulu" }
];